import { Ref, ref } from 'vue'
import { Connection } from './models/Connection'

export const showConfirmationDialog = ref(false)
export const showPromptDialog = ref(false)
export const showAlertDialog = ref(false)
export const dialogMessage:Ref<string|string[]|null> = ref(null)
export const yesNoConfirmation = ref(false)
let resolvePromise:((value: any) => void)|null = null
let rejectPromise:((reason?: any) => void)|null = null
let confirmCallback:((yesOrNo?: any) => void)|null = null

export const confirm = (msg: string, cb = (yesOrNo?:boolean):boolean|void => yesOrNo, yesNo = false) => {
  confirmCallback = cb
  dialogMessage.value = msg
  yesNoConfirmation.value = yesNo
  showConfirmationDialog.value = true

  return new Promise((resolve, reject) => {
    resolvePromise = resolve
    rejectPromise = reject
  })
}

export const dialogOk = (value: any) => {
  return Promise.resolve(confirmCallback?.(value)).then(resolvePromise, rejectPromise)
}

export const prompt = (msg: string, cb = (value?:string) => value) => {
  confirmCallback = cb
  dialogMessage.value = msg
  showPromptDialog.value = true

  return new Promise<string|undefined>((resolve, reject) => {
    resolvePromise = resolve
    rejectPromise = reject
  })
}

export const alert = (msg: string|string[]) => {
  dialogMessage.value = msg
  showAlertDialog.value = true
}

export const getJitsiId = (connection:Connection) => {
  const fulljid = connection.connection.getJid().split('@')[0]
  const userId = fulljid.split('-')[0]
  return userId
}

<template>
  <div>
    Your browser is not supported. Please use Chrome or Firefox instead.
  </div>
</template>

<style lang="scss" scoped>
div {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: bold;
}
</style>

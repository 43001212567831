<template>
  <div class="root">
    <Permission type="audio" icon="bxs-microphone" icon2="bxs-microphone-off" />
    <Permission type="video" icon="bxs-video" icon2="bxs-video-off" />
    <Permission type="chat" icon="bxs-message-rounded-check" icon2="bxs-message-rounded-x" />
    <Permission type="whiteboard" icon="bxs-edit-alt" />
    <Permission type="screenshare" icon="bx-outline" />
    <div @click="app.showRoomMenu = !app.showRoomMenu">
      <i class="bx bx-menu" />
    </div>

    <div v-if="app.showRoomMenu" class="panel">
      <i class="bx bx-x" @click="app.showRoomMenu = false" />
      <ul>
        <li @click="collect">Bring Users Back to Main Room</li>
        <li @click="sendBack">Send Users Back to Breakout Rooms</li>
        <li @click="copySlidesToMainRoom">Copy Boards to Main Room</li>
        <li @click="copySlideToBreakoutRooms">Send Board to Breakout Rooms</li>
        <li @click="closeRooms">Close all Breakout Rooms</li>
        <li @click="cloakUser">Cloak / Uncloak</li>
      </ul>

      <hr />

      <div class="breakout-room-form">
        <span class="panel-heading">Create Breakout Room</span>

        <label class="input">
          <span>Room Name</span>
          <input v-model="roomName" :style="{ 'background-color': roomName.length > MAX_ROOM_NAME_LENGTH ? 'red' : undefined }" />
          <span v-if="roomName.length > MAX_ROOM_NAME_LENGTH">
            {{ `Uh oh! Keep it below ${MAX_ROOM_NAME_LENGTH} characters.` }}
          </span>
        </label>
        <label class="input">
          <span>Number / Group Size</span>
          <input v-model="numberOfRooms" />
        </label>

        <div class="radios">
          <label>
            <input v-model="distribute" type="radio" name="distribute" value="no" />
            Don't move users
          </label>
          <label>
            <input v-model="distribute" type="radio" name="distribute" value="distribute" />
            Distribute users equally
          </label>
          <label>
            <input v-model="distribute" type="radio" name="distribute" value="group" />
            In groups of
          </label>
        </div>

        <label class="checkbox">
          <input v-model="includeMods" type="checkbox" />
          Include Teachers
        </label>

        <label class="checkbox">
          <input v-model="includeCurrentBoard" type="checkbox" />
          Send Current Board
        </label>

        <button @click="createRooms">Create</button>
      </div>
    </div>
  </div>

  <Teleport to="body">
    <Dialog v-model="showRoomListModal" @ok="sendSlideToRooms(false)">
      Which rooms do you want to send to?
      <br />
      <br />
      <div class="all-none-container">
        <div class="all-none" @click="allOrNone(true)">All</div>
        <div class="all-none" @click="allOrNone(false)">None</div>
      </div>
      <br />
      <label v-for="room in roomsWithoutMain" :key="room.name">
        <input v-model="room.enabled" type="checkbox" />
        {{ room.displayName }}
      </label>
    </Dialog>
  </Teleport>
</template>

<style lang="scss" scoped>
@import '../main.scss';

div.root {
  display: flex;
  height: 40px;
  flex-shrink: 0;
  background: linear-gradient(180deg, rgba(208, 208, 208, 1) 0%, rgba(255, 255, 255, 1) 26%);
  border-top: 1px solid white;
  justify-content: space-between;
  padding: 0 5px;
  align-items: flex-end;
  position: relative;
}

div.root > div > i {
  font-size: 20px;
  width: 32px;
  height: 32px;
  line-height: 28px;
  text-align: center;
}

div.root label {
  display: block;
}

div.root > div.panel {
  position: absolute;
  left: calc(100% + 12px);
  top: 10px;
  width: 250px;
  min-height: 0;
  background: $gray;
  display: flex;
  flex-direction: column;
  padding: 25px 0;
  color: white;

  &::before {
    position: absolute;
    content: '';
    top: 3px;
    left: -7px;
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    background: $gray;
  }

  > i {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: $icon-highlight;
    color: white;
    font-size: 22px;
    cursor: pointer;
  }

  > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    user-select: none;

    > li {
      padding: 5px 10px;
      cursor: pointer;
      &:hover {
        background: $icon-highlight;
      }
    }
  }

  > hr {
    margin-top: 10px;
    width: 100%;
  }

  > div.breakout-room-form {
    padding: 10px;
    display: flex;
    flex-direction: column;

    > span.panel-heading {
      font-weight: bold;
      margin: 10px 0;
    }

    > label.input {
      display: flex;
      flex-direction: column;
    }

    > div.radios,
    label.checkbox,
    label.input {
      margin: 10px 0;
    }

    > button {
      border-radius: 5px;
      background: $icon-highlight;
      border: none;
      font-weight: bold;
      color: white;
      height: 25px;
    }
  }
}

div.all-none-container {
  display: inline-block;
  text-align: center;
}

div.all-none {
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
  margin-right: 15px;
  margin-left: 15px;
}
</style>

<script lang="ts">
import { computed, ref, watch, defineComponent } from 'vue';
import { getApp } from '../models/App';
import Permission from './Permission.vue';
import Dialog from './Dialog.vue';
import { MAIN_ROOM, Permissions } from '../types';
import axios from 'axios';
import { config, MAX_ROOM_NAME_LENGTH } from '../config';
import { alert, confirm } from '../utils';
export default defineComponent({
  name: 'Permissions',
  components: {
    Permission,
    Dialog,
  },
  setup() {
    const app = getApp();

    const showRoomListModal = ref(false);
    const roomsWithoutMain = computed(() => {
      return app.connection.rooms.filter((r) => r.name !== MAIN_ROOM);
    });

    const setPermission = (type: keyof Permissions, value: boolean) => {
      app.setGlobalPermission(type, value);
    };

    const roomName = ref('Room');
    const numberOfRooms = ref(2);
    const distribute = ref('group');
    const includeMods = ref(false);
    const includeCurrentBoard = ref(false);

    const createRooms = () => {
      // Having a / in the room name causes problems with the whiteboard room/naming convention.
      if (roomName.value.includes('/')) {
        alert('Breakout room names cannot contain the "/" character.');
        return;
      }

      const slideToSend = app.connection.currentRoom?.currentSlide;

      if (includeCurrentBoard.value) {
        // Doing setTimeout() because I want to make sure the rooms get created before sending the board.
        // It would be better to do this based on some kind of confirmation/return that the rooms exist though.
        // Does this ^ exist?
        setTimeout(() => {
          sendSlideToRooms(true, slideToSend);
        }, 1000);
      }

      app.send(`[breakout]create:${roomName.value}:${numberOfRooms.value}:${distribute.value}:${includeMods.value}:all`);
    };

    const collect = () => {
      app.restrictedSend('[breakout]collect');
    };

    const sendBack = () => {
      app.restrictedSend('[breakout]sendBack');
    };

    const copySlidesToMainRoom = () => {
      app.status = 'Copying...';
      const mainRoom = app.connection.rooms.find((r) => r.name === MAIN_ROOM);
      const index = mainRoom?.teachersCurrentSlide;

      // Append the room.displayName on using | as a separator
      const srcs = roomsWithoutMain.value.map((n) => 'src=' + n.serverName + '|' + n.displayName).join('&');

      axios
        .get(`${config.whiteBoardUrl}ws/command/copy/${app.connection.name}/${index}?${srcs}`)
        .then(() => {
          app.status = 'Done!';
        })
        .catch(() => {
          app.status = 'There was an error copying the slides!';
        });
    };

    const copySlideToBreakoutRooms = () => {
      roomsWithoutMain.value.forEach((r) => {
        r.enabled = true;
      });

      showRoomListModal.value = true;
    };

    const sendSlideToRooms = (all: boolean, slideToSend = app.connection.currentRoom?.currentSlide) => {
      app.status = 'Copying...';

      // I don't like having to do this, but I couldn't come up with a more elegant way.
      let dests = '';

      // The "all" parameter is used to send the slideToSend board to all newly created breakout rooms vs. the ones that were selected via checkbox.
      if (all) {
        dests = roomsWithoutMain.value.map((r) => 'dest=' + r.serverName).join('&');
      } else {
        dests = roomsWithoutMain.value
          .filter((r) => r.enabled)
          .map((r) => 'dest=' + r.serverName)
          .join('&');
      }
      console.log(slideToSend);
      console.log(app.connection.currentRoom?.teachersCurrentSlide);
      console.log(dests);
      axios
        .get(`${config.whiteBoardUrl}ws/command/copy2/${app.connection.name}/${slideToSend}?${dests}`)
        .then((resp) => {
          app.status = 'Done!';

          const slideIndexes = resp.data.indexes;

          console.log(resp);
          console.log(resp.data);
          console.log(slideIndexes);

          roomsWithoutMain.value
            .filter((r) => r.enabled)
            .forEach((d, i) => {
              app.send(`[slide]${d.name}:${slideIndexes[i]}:true`);
            });
        })
        .catch(() => {
          app.status = 'There was an error copying the slides!';
        });
    };

    const closeRooms = async () => {
      // Make sure it wasn't an accidental click to close breakout rooms.
      const yes = await confirm('Do you really want to close all breakout rooms?', undefined, true);

      if (yes) {
        app.restrictedSend('[breakout]close');
        // Update the event log about the fact that the breakout rooms were closed.
        app.send(`[eventLog]All rooms were closed by ${app.getUser()?.name}.`);
      }
    };

    const cloakUser = async () => {
      const user = app.getUser();
      user.cloaked = !user.cloaked;
      app.restrictedSend(`[cloak]${user.cloaked}`);
    };

    const allOrNone = (all: boolean) => {
      if (all) {
        roomsWithoutMain.value.forEach((r) => {
          r.enabled = true;
        });
      } else {
        roomsWithoutMain.value.forEach((r) => {
          r.enabled = false;
        });
      }
    };

    const inputLength = () => {
      //   if (all) {
      //     roomsWithoutMain.value.forEach(r => {
      //       r.enabled = true
      //     })
      //   } else {
      //     roomsWithoutMain.value.forEach(r => {
      //       r.enabled = false
      //     })
      //   }
    };

    return {
      app,
      setPermission,
      roomName,
      numberOfRooms,
      distribute,
      includeMods,
      createRooms,
      collect,
      sendBack,
      copySlidesToMainRoom,
      copySlideToBreakoutRooms,
      closeRooms,
      roomsWithoutMain,
      sendSlideToRooms,
      showRoomListModal,
      includeCurrentBoard,
      allOrNone,
      inputLength,
      MAX_ROOM_NAME_LENGTH,
      cloakUser,
    };
  },
});
</script>

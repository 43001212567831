<template>
  <label :class="{active: app.permissions[type]}">
    <input
      type="checkbox"
      :checked="app.permissions[type]"
      hidden
      @change="app.setGlobalPermission(type, ($event.target as HTMLInputElement).checked)"
    >
    <i
      class="bx"
      :class="!app.permissions[type] && icon2 ? icon2 : icon"
    />
  </label>
</template>

<style lang="scss" scoped>
@import '../main.scss';

label {
  font-size: 20px;
  width: 32px;
  height: 32px;
  line-height: 28px;
  text-align: center;
  position: relative;
  color: $light-gray;
  cursor: pointer;

  &.active {
    color: $black;
  }

  &::after {
    display: table;
    content: '';
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 4px;
    left: 0px;
    background: $light-light-gray;
  }

  &.active::after {
    background: $selected-icon-bar-color;
  }

  &:hover {
    outline: 1px solid black;
  }
}

</style>

<script lang="ts">
import { getApp } from '../models/App'
import { defineComponent, PropType } from 'vue'
import { Permissions } from '../types'
export default defineComponent({
  name: 'Permission',
  props: {
    icon: String,
    icon2: String,
    type: { type: String as PropType<keyof Permissions>, required: true }
  },
  setup () {
    return { app: getApp() }
  }
})
</script>

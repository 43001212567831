import { type LogLevels } from './log';

export const ROOM = 'Main Room';

export const DEFAULT_ROOMS_HEIGHT = 425;

export const LOG_LEVEL: LogLevels = 3;

export const MAX_ROOM_NAME_LENGTH = 20;

export const PREFERENCELIST = {
  myRaiseHandDing: 'Raising My Hand',
  otherRaiseHandDing: 'Others Raise Their Hand',
  myLowerHandDing: 'Hand is Lowered',
  joinedSessionDing: 'Someone Joined the Session',
  leftSessionDing: 'Someone Left the Session',
  privateChatReceivedDing: 'Received Private Chat',
};

export const config = {
  // whiteBoardUrl: 'https://localhost:3001/',
  whiteBoardUrl: 'https://whiteboard.onlinelearningsessions.com/',
  hosts: {
    // domain: 'meet.jit.si',
    // muc: 'conference.meet.jit.si',
    // focus: 'focus.meet.jit.si'
    domain: 'sessions.onlinelearningsessions.com',
    muc: 'conference.sessions.onlinelearningsessions.com',
    focus: 'focus.sessions.onlinelearningsessions.com',
  },
  // externalConnectUrl: 'https://meet.jit.si/http-pre-bind',
  // externalConnectUrl: 'https://alpha.jitsi.net/http-pre-bind',
  enableP2P: false,
  p2p: {
    enabled: false,
    preferH264: true,
    disableH264: true,
    useStunTurn: true,
  },
  useStunTurn: true,
  // serviceUrl: `wss://meet.jit.si/xmpp-websocket?room=${ROOM}`,
  // bosh: `https://meet.jit.si/http-bind?room=${ROOM}`,
  // bosh: `https://alpha.jitsi.net/http-bind?room=${ROOM}`,
  // websocket: `wss://meet.jit.si/xmpp-websocket?room=${ROOM}`,
  // websocket: `wss://alpha.jitsi.net/xmpp-websocket?room=${ROOM}`,
  serviceUrl: 'wss://sessions.onlinelearningsessions.com/xmpp-websocket',
  // serviceUrl: 'https://sessions.onlinelearningsessions.com/http-bind',
  clientNode: 'http://jitsi.org/jitsimeet',
  xmppPing: {
    threshold: 5,
    interval: 3000,
    timeout: 2000,
  },
} as const;

<template>
  <div
    style="position: inherit;"
    :width="300"
    height="auto"
  >
    <div class="video-header">
      <span>VIDEO</span>
    </div>
    <div class="videos">
      <div>
        <video
          v-for="track in tracks"
          :key="track.getTrackId() + track.getTrackLabel()"
          :ref="(ref) => track && ref && track.attach(ref as HTMLVideoElement)"
          autoplay
          :class="{
            local: track.isLocal(),
            focus: track === focus,
            video: true
          }"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../main.scss';
div.videos {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: white;
  box-sizing: border-box;
  border: 4px solid black;

  > div {

    display: flex;
    flex-wrap: wrap;

    > video {
      min-width: 100px;
      max-width: 200px;
      flex: 1 1;
      border: none;

      &.local {
        transform: scaleX(-1);
      }

      &.focus {
        order: -1;
        min-width: 100%;
        max-width: 100%;
      }
    }
  }
}

div.video-header {
  display: flex;
  font-size: 14px;
  height: 26px;
  color: white;
  background: $gray;
  align-items: center;
  padding: 0 6px;

  > span {
    flex-grow: 1;
  }
}
</style>

<script lang="ts">
import { getApp } from '../models/App'
import { computed, defineComponent } from 'vue'
export default defineComponent({
  name: 'VideoBox',
  setup () {
    const app = getApp()
    const tracks = computed(() => app.connection.currentRoom?.tracks.filter(t => t.getType() === 'video' && t.getVideoType() !== 'desktop'))

    const focus = computed(() => {
      const conf = app.connection.currentRoom

      if (!conf) return null

      return conf.getDominantVideo() || conf.getNonLocalVideo() || conf.getLocalVideo()
    })

    return { app, tracks, focus }
  }
})
</script>

<template>
  <div>
    <span><slot>{{ name }}</slot></span>
    <label
      :class="{active: audio, disabled: audioDisabled}"
    >
      <i
        class="bx"
        :class="audio ? 'bxs-microphone' : 'bxs-microphone-off'"
      />
      <input
        type="checkbox"
        :checked="audio"
        hidden
        @change="$emit('update:audio', ($event.target as HTMLInputElement).checked)"
      >
    </label>
    <label
      :class="{active: video, disabled: videoDisabled }"
    >
      <i
        class="bx"
        :class="video ? 'bxs-video' : 'bxs-video-off'"
      />
      <input
        type="checkbox"
        :checked="video"
        hidden
        @change="$emit('update:video', ($event.target as HTMLInputElement).checked)"
      >
    </label>
    <!-- <ul
      v-if="showVideoDevices"
      class="contextmenu"
      style="top: 50px; left: 215px;"
    >
      <li
        v-for="device in videoDevices"
        :key="device.deviceId"
        :class="{active: device.deviceId === app.cameraDeviceId }"
        @click="selectVideo(device.deviceId)"
      >
        {{ device.label }}
      </li>
    </ul> -->
  </div>
</template>

<style scoped lang="scss">
@import '../main.scss';

div {
  display: flex;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  color: white;
  align-items: stretch;
  position: relative;
  // padding: 0 6px;
}

div > span {
  flex-grow: 1;
  text-overflow: ellipsis;
  padding: 0 10px;
}

div > label {
  color: $light-gray;
  padding: 0 4px;
  font-size: 26px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    outline: 1px solid white;
  }

  &.active {
    color: $black;
    background: $icon-highlight;
  }

  &.disabled {
    pointer-events: none;
    color: $gray;
  }
}

</style>

<script lang="ts">
import { computed, ref, defineComponent } from 'vue'
import { getApp } from '../models/App'
export default defineComponent({
  name: 'NameAndControls',
  props: [ 'audio', 'video', 'audioDisabled', 'videoDisabled', 'devices' ],
  emits: [ 'update:audio', 'update:video' ],
  setup () {
    const app = getApp()

    const name = computed(() => app.connection.currentRoom?.getLocalUser()?.name ?? '...')

    return {
      app,
      name
    }
  }
})
</script>

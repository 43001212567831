import { Model, Dehydrate } from 'vue-model';
import { Room } from './Room';
// eslint-disable-next-line camelcase
import { jwtDecode } from 'jwt-decode';
// import JitsiMeetJS from '@lyno/lib-jitsi-meet'
import type { JitsiConferenceErrors } from '@solyd/lib-jitsi-meet/dist/esm/JitsiConferenceErrors';
import type JitsiLocalTrack from '@solyd/lib-jitsi-meet/dist/esm/modules/RTC/JitsiLocalTrack';

export class Connection extends Model {
  static model = 'Connection';

  id!: string;
  rooms!: Room[];
  connection: any;
  currentRoomName!: string | null;
  currentRoom!: Room | null;
  localTracksPromise: Promise<JitsiLocalTrack[]>;
  connected!: boolean;
  copiedSlide!: Dehydrate<typeof Model> | null;
  token!: string;
  name!: string;
  overlayMessage!: string;
  switchPromise!: Promise<any>;
  switching!: boolean;

  static fields() {
    return {
      id: this.uid(),
      connected: this.boolean(false),
      rooms: this.hasMany(Room),
      currentRoomName: this.string(null),
      currentRoom: this.belongsTo(Room, 'currentRoomName'),
      name: this.string(),
      overlayMessage: this.string('Joining...'),
      switching: this.boolean(false),
    };
  }

  constructor(baseRoom: string, token: string, options: any) {
    // Override used by jibri to login to the conference
    let id = window.localStorage.getItem('xmpp_username_override');
    const password = window.localStorage.getItem('xmpp_password_override');

    // Use the token if one was provided
    if (token) {
      const data = jwtDecode<{
        context: { name: string; fullName: String; role: 'student' | 'teacher'; roomName: string; id: string };
      }>(token);
      id = data.context.id;
    }

    super({ id, name: baseRoom });

    this.copiedSlide = null;

    options.serviceUrl += '?room=' + baseRoom + (token ? '&token=' + token : '');

    this.localTracksPromise = JitsiMeetJS.createLocalTracks({ devices: ['audio', 'video'] }).catch(
      (err: JitsiConferenceErrors) => {
        console.log('[~connection]', 'error creating local tracks', err);
        return [];
      },
    ) as Promise<JitsiLocalTrack[]>;

    const connection = new JitsiMeetJS.JitsiConnection(undefined, token, options);
    this.connection = connection;

    this.onConnectionSuccess = this.onConnectionSuccess.bind(this);
    this.onConnectionFailed = this.onConnectionFailed.bind(this);
    this.onConnectionDisconnected = this.onConnectionDisconnected.bind(this);

    connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, this.onConnectionSuccess);

    connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_FAILED, this.onConnectionFailed);

    connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, this.onConnectionDisconnected);

    // JitsiMeetJS.mediaDevices.addEventListener(
    //   JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
    //   onDeviceListChanged)

    console.log('[~connection]', 'connecting');

    if (id && id.length > 0 && password && password.length > 0) {
      connection.connect({ id, password });
    } else {
      connection.connect({});
    }
  }

  onConnectionSuccess() {
    console.log('[~connection]', 'connected');
    this.connected = true;
  }

  onConnectionFailed(...args: any[]) {
    console.log('[~connection]', 'connecting failed', ...args);
    this.emit('connection-failed');
  }

  onConnectionDisconnected() {
    if (!this.connected) return;
    this.overlayMessage = 'You were disconnected from the conference. You can rejoin by refreshing your page.';
  }

  async disconnect() {
    console.log('[~connection]', 'disconnect');
    this.overlayMessage = 'See you later!';
    this.connected = false;

    this.connection.removeEventListener(JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, this.onConnectionSuccess);

    this.connection.removeEventListener(JitsiMeetJS.events.connection.CONNECTION_FAILED, this.onConnectionFailed);

    this.connection.removeEventListener(JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, this.disconnect);

    await this.currentRoom?.conference?.leave();

    await this.localTracksPromise.then((tracks) => {
      return Promise.all(
        tracks.map((tracks) => {
          return tracks.dispose();
        }),
      );
    });

    return this.connection.disconnect();
  }

  async join(name: string) {
    if (this.currentRoomName) {
      await this.leave();
    }

    console.log('[~connection] attempt to join room', name);

    const conference = Room.create({ connectionId: this.id, name });
    // rooms.set(name, { users: [], conference: markRaw(conference), whiteboardName: conference.name, currentWhiteboardName: conference.name })
    this.currentRoomName = name;
    return conference.join();
  }

  async leave() {
    console.log('[~connection] attempt to leave room', this.currentRoomName);

    await this.currentRoom?.leave();
    this.currentRoomName = null;
  }

  async switch(name: string) {
    console.log('[~connection] attempt to switch to room', name);

    this.switchPromise = (this.switchPromise || Promise.resolve()).then(() => {
      this.overlayMessage = 'Switching rooms';
      this.switching = true;

      console.log('Starting switch to', name);

      if (this.currentRoomName === name) {
        console.log('[~connection] already in room. No switch required');
        if (this.currentRoom?.serverName !== this.currentRoom?.conference.options.name) {
          console.error('[~connection] jitsi server name does not match room name');
        }
        this.switching = false;
        return;
      }

      let promise = Promise.resolve();

      if (this.currentRoom) {
        const currentRoom = this.currentRoom;
        const currentRoomName = currentRoom.name;

        promise = currentRoom.joinPromise.then(() => {
          if (currentRoom.joining) {
            console.error('[~connection] attempt to switch to', name, 'while still joining', currentRoomName);
          }

          return currentRoom.leave().catch((e) => {
            console.error('[~connection] error while leaving room', currentRoomName, e);
          });
        });
      }

      return promise.then(() => {
        this.getConferenceForRoom(name)
          .join()
          .then(() => {
            this.currentRoomName = name;
            this.switching = false;
          })
          .catch((e) => {
            this.overlayMessage = 'Failed to switch rooms. Refresh the page!';
            console.error('[~connection] failed to switch to room', name, e);

            const room = Room.all().find((room) => room.conference.isJoined());
            if (room) {
              console.info('Jitsi says, that we are still joined in room', room.name);
            } else {
              console.info('Jitsi says, that we are not joined in any room');
            }
          });
      });
    });

    return this.switchPromise;
  }

  getConferenceForRoom(name: string) {
    let room = (Room as any).get(name) as Room;
    if (!room) {
      room = Room.create({ connectionId: this.id, name });
    }

    // if (!room.conference || (!room.conference.joined)) {
    //   room.init()
    // }

    return room; // .conference
  }
}

<template>
  <vue-resizable
    style="position: absolute; z-index: 20"
    :width="300"
    height="auto"
    :left="600"
    :top="200"
    drag-selector=".drag-handle > div"
  >
    <div class="videos">
      <div class="drag-handle">
        <div />
        <i
          class="bx bx-x"
          title="Close"
          @click="app.showVideo = false"
        />
      </div>
      <div>
        <video
          v-for="track in tracks"
          :key="track.getTrackId() + track.getTrackLabel()"
          :ref="(ref) => track && ref && track.attach(ref as HTMLVideoElement)"
          autoplay
          :class="{
            local: track.isLocal(),
            focus: track === focus,
            video: true
          }"
        />
      </div>
    </div>
  </vue-resizable>
</template>

<style lang="scss" scoped>
@import '../main.scss';
div.videos {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: white;
  box-sizing: border-box;
  border: 4px solid black;

  > .drag-handle {
    height: 25px;
    background: $gray;
    display: flex;
    cursor: move;

    > div {
      flex-grow: 1;
      height: 25px;
    }

    > i {
      background: $icon-highlight;
      color: white;
      cursor: pointer;
      width: 25px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      font-size: 25px;
    }
  }

  > div {

    display: flex;
    flex-wrap: wrap;

    > video {
      min-width: 100px;
      max-width: 200px;
      flex: 1 1;
      border: none;

      &.local {
        transform: scaleX(-1);
      }

      &.focus {
        order: -1;
        min-width: 100%;
        max-width: 100%;
      }
    }
  }
}
</style>

<script lang="ts">
import { getApp } from '../models/App'
import { computed, ref, defineComponent } from 'vue'
import VueResizable from './vue-resizable.vue'
export default defineComponent({
  name: 'VideoBox',
  components: {
    VueResizable
  },
  setup () {
    const app = getApp()
    const tracks = computed(() => app.connection.currentRoom?.tracks.filter(t => t.getType() === 'video' && t.getVideoType() !== 'desktop'))

    const focus = computed(() => {
      const conf = app.connection.currentRoom

      if (!conf) return null

      return conf.getDominantVideo() || conf.getNonLocalVideo() || conf.getLocalVideo()
    })

    const style = ref({ top: '0px', right: '0px' })

    return { app, tracks, focus, style }
  }
})
</script>

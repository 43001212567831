import { createApp, watch } from 'vue'
import './index.css'
import App from './App.vue'
import router from './router'
import { App as AppModel } from './models/App'
import { modelRegistry } from 'vue-model'

import VueChatScroll from 'vue-chat-scroll'
import 'boxicons/css/boxicons.min.css'
import { Device } from './types'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faHandPaper } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import type JitsiMeetJSType from '@solyd/lib-jitsi-meet'

library.add(faHandPaper)

// JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.DEBUG)
JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR)

const app = AppModel.create({})

const unload = (ev: Event) => {
  if (app?.recorderState !== 'off') {
    ev.preventDefault()
    ev.stopImmediatePropagation()
    ev.returnValue = 'Please stop recording before closing' as unknown as boolean
    return 'Please stop recording before closing'
  } else {
    if (app.isScreenShareEnabled) {
      app.isScreenShareEnabled = false
    }
    app?.connection.disconnect()
  }
}

window.addEventListener('beforeunload', unload)

const initOptions = {
  disableAudioLevels: false
  // enableWindowOnErrorHandler: true
}

JitsiMeetJS.init(initOptions)

document.addEventListener('drop', (e) => e.preventDefault(), { capture: true })

createApp(App)
  .use(VueChatScroll)
  .use(router)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')

// const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) || window.webkitConvertPointFromNodeToPage !== undefined

// if (isSafari) {
//   router.replace('/unsupported-browser')
// }

if (JitsiMeetJS.mediaDevices.isDeviceListAvailable() as unknown as boolean) {
  JitsiMeetJS.mediaDevices.enumerateDevices((devices: Device[]) => {
    app.devices = devices
  })
}

JitsiMeetJS.mediaDevices.addEventListener(JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED, (devices:Device[]) => {
  app.devices = devices
  // if (JitsiMeetJS.mediaDevices.isDeviceListAvailable()) {
  //   JitsiMeetJS.mediaDevices.enumerateDevices((devices: Device[]) => {
  //     app.devices = devices
  //   })
  // }
})

const audioCtx = new (window.AudioContext || (window as any).webkitAudioContext)() as AudioContext
const gainNode = audioCtx.createGain()

app.gainNode = gainNode
app.audioCtx = audioCtx

watch(() => app.inputGain, (newValue) => {
  gainNode.gain.setValueAtTime(newValue, audioCtx.currentTime)
})

declare global {
  const JitsiMeetJS: typeof JitsiMeetJSType
  interface Window {
    app: AppModel
    models: typeof modelRegistry
    APP: {
      conference: {
        _room: {
          joined: boolean
          isJoined (): boolean
        }
      }
    }
  }
}

window.app = app
window.models = modelRegistry
window.APP = {
  conference: {
    _room: {
      joined: false,
      isJoined () {
        return this.joined
      }
    }
  }
}

<template>
  <div>
    Whoops!
    <br><br>
    The session you are trying to join doesn't exist.  Please try again, or contact us for assistance.
  </div>
</template>

<style lang="scss" scoped>
div {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  padding: 20px;
}
</style>
<template>
  <Dialog
    v-model="show"
    title="Confirm"
    :yes-or-no="yesNo"
    @ok="ok"
  >
    <p>{{ msg }}</p>
  </Dialog>
</template>

<script lang="ts">
import Dialog from './Dialog.vue'
import { showConfirmationDialog, dialogMessage, yesNoConfirmation, dialogOk } from '../utils'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ConfirmDialog',
  components: { Dialog },
  setup () {
    const show = showConfirmationDialog
    const msg = dialogMessage
    const yesNo = yesNoConfirmation

    const ok = (yesOrNo?:boolean) => {
      dialogOk(yesOrNo)
    }

    return {
      ok,
      show,
      msg,
      yesNo
    }
  }
})
</script>

import { Model } from 'vue-model';
import { ChatMessage, MAIN_ROOM, Permissions } from '../types';
import { Room } from './Room';

export class User extends Model {
  static model = 'User';

  name!: string;
  fullName!: string;
  id!: string;
  jitsiId!: string;
  role!: string;
  user: any;
  emoji!: string;
  feedback!: string;
  userStatus!: string;
  hand!: boolean;
  handTime!: number;
  permissions!: Permissions;
  roomId!: string;
  messages!: ChatMessage[];
  connectionQuality!: number;
  visible!: boolean;
  audioLevel!: number;
  isTyping!: boolean;
  isDrawing!: boolean;
  isOnScreenShare!: boolean;
  isOnCamera!: boolean;
  isOnAudio!: boolean;
  firstJoinedTime!: number;
  lastLeftTime!: number;
  selected!: boolean;
  timerExpirationDate!: number;
  awayTimeStamp!: number;
  absentTimeStamp!: number;
  awayTime!: number;
  absentTime!: number;
  kickedCount!: number;
  justJoined!: boolean;
  confetti!: boolean;
  topMenuControl!: boolean;
  cloaked!: boolean;
  participationCount!: number;
  oldPermissions: Permissions = {
    audio: true,
    video: false,
    chat: true,
    screenshare: false,
    whiteboard: true,
    rooms: false,
    navigate: true,
    topMenuControl: false,
  };

  static fields() {
    return {
      id: this.string(),
      jitsiId: this.string(),
      roomId: this.string(MAIN_ROOM),
      room: this.belongsTo(Room),
      name: this.string(''),
      fullName: this.string(''),
      role: this.string('student'),
      emoji: this.string(''),
      feedback: this.string(''),
      userStatus: this.string(''),
      permissions: this.field<Permissions>(
        {
          audio: true,
          video: false,
          chat: true,
          screenshare: false,
          whiteboard: true,
          rooms: false,
        },
        Object as any,
      ),
      hand: this.boolean(),
      handTime: this.number(0),
      messages: this.array<ChatMessage>([], Object as any),
      connectionQuality: this.number(100),
      visible: this.boolean(true),
      audioLevel: this.number(0),
      isTyping: this.boolean(false),
      isDrawing: this.boolean(false),
      isOnScreenShare: this.boolean(false),
      isOnCamera: this.boolean(false),
      isOnAudio: this.boolean(false),
      firstJoinedTime: this.number(0),
      lastLeftTime: this.number(0),
      selected: this.boolean(false),
      timerExpirationDate: this.number(0),
      awayTimeStamp: this.number(0),
      absentTimeStamp: this.number(0),
      awayTime: this.number(0),
      absentTime: this.number(0),
      kickedCount: this.number(0),
      participationCount: this.number(0),
      justJoined: this.boolean(false),
      confetti: this.boolean(false),
      topMenuControl: this.boolean(false),
      cloaked: this.boolean(false),
    };
  }

  static getByJitsiId(jitsiId: string) {
    return this.whereFirst({ jitsiId })!;
  }
}

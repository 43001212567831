<template>
  <Conference v-if="!loading" :id="id" />

  <div v-else>
    {{ loadingMessage }}
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getApp } from '../models/App';
import { Connection } from '../models/Connection';
import { config, PREFERENCELIST } from '../config';

import Conference from './Conference.vue';

import { getJitsiId } from '../utils';

const soundEffects = import.meta.glob('../assets/sounds/effects/*.mp3', { eager: true });
const sounds = import.meta.glob('../assets/sounds/*.mp3', { eager: true });

export default defineComponent({
  components: {
    Conference,
  },
  props: ['id'],
  setup(props) {
    const loading = ref(true);
    const route = useRoute();
    const router = useRouter();
    const token = route.query.token as string;

    // Handle the "loading message" when the page initially loads
    const loadingMessage = ref('Working on it...');

    const isRecorder = route.hash.startsWith('#config.iAmRecorder=true');

    if (!token) {
      if (!isRecorder) {
        throw new Error('No Token provided');
      }
    }

    const app = getApp();
    const connection = new Connection(props.id, token, config).save();

    connection.on('connection-failed', () => {
      loadingMessage.value =
        'Uh oh! Something is wrong with the link you are using to join the session. Please get a new one.';
    });

    app.isRecorder = isRecorder;

    app.connection = connection;

    app.sounds = Object.fromEntries(
      Object.entries(sounds).map(([key, value]) => {
        key = key.split('/').pop()!.split('.').shift()!;
        return [key, new Audio(value.default)];
      }),
    );

    app.soundEffects = Object.fromEntries(
      Object.entries(soundEffects).map(([key, value]) => {
        key = key.split('/').pop()!.split('.').shift()!;
        return [key, new Audio(value.default)];
      }),
    );

    watch(
      () => connection.connected,
      (newValue) => {
        if (newValue) {
          const jitsiId = getJitsiId(connection);
          app.initWs(isRecorder, isRecorder ? 'recorder' : token, jitsiId);
          loading.value = false;
        }
      },
    );

    watch(
      () => app.isAudioEnabled,
      (newValue) => {
        app.send(`[track]0${app.connection.id}:${newValue}`);
      },
    );
    watch(
      () => app.isVideoEnabled,
      (newValue) => {
        app.send(`[track]1${app.connection.id}:${newValue}`);
      },
    );
    watch(
      () => app.isScreenShareEnabled,
      (newValue) => {
        app.send(`[track]2${app.connection.id}:${newValue}`);
      },
    );

    onBeforeUnmount(() => {
      app.ws?.close();
      connection.disconnect();
    });

    // If the user has not set preferences yet, mark them all as checked/on by default
    for (const [key, value] of Object.entries(PREFERENCELIST)) {
      if (localStorage.getItem(key) === null) {
        localStorage.setItem(key, JSON.stringify(true));
      }
    }

    return {
      loading,
      loadingMessage,
    };
  },
});
</script>

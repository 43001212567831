<template>
  <div
    ref="parent"
    class="resizable-component"
    :style="style"
  >
    <slot />
    <div
      v-for="el in active"
      v-show="!maximize"
      :key="el"
      :class="'resizable-' + el"
    />
  </div>
</template>

<script lang="ts">
import { toRefs, computed, ref, watch, reactive, onMounted, onBeforeUnmount, Ref, defineComponent, PropType } from 'vue'

const ELEMENT_MASK = {
  'resizable-r': { bit: 0b0001, cursor: 'e-resize' },
  'resizable-rb': { bit: 0b0011, cursor: 'se-resize' },
  'resizable-b': { bit: 0b0010, cursor: 's-resize' },
  'resizable-lb': { bit: 0b0110, cursor: 'sw-resize' },
  'resizable-l': { bit: 0b0100, cursor: 'w-resize' },
  'resizable-lt': { bit: 0b1100, cursor: 'nw-resize' },
  'resizable-t': { bit: 0b1000, cursor: 'n-resize' },
  'resizable-rt': { bit: 0b1001, cursor: 'ne-resize' },
  'drag-el': { bit: 0b1111, cursor: 'move' }
}

const CALC_MASK = {
  l: 0b0001,
  t: 0b0010,
  w: 0b0100,
  h: 0b1000
}

export default defineComponent({
  name: 'VResizable',
  props: {
    width: {
      default: undefined,
      type: [ Number, String ]
    },
    minWidth: {
      default: 0,
      type: Number
    },
    maxWidth: {
      default: undefined,
      type: Number
    },
    height: {
      default: undefined,
      type: [ Number, String ]
    },
    minHeight: {
      default: 0,
      type: Number
    },
    maxHeight: {
      default: undefined,
      type: Number
    },
    left: {
      default: 0,
      type: [ Number, String ]
    },
    top: {
      default: 0,
      type: [ Number, String ]
    },
    active: {
      default: () => [ 'r', 'rb', 'b', 'lb', 'l', 'lt', 't', 'rt' ],
      validator: (val: string[]) => {
        const possibleValues = [ 'r', 'rb', 'b', 'lb', 'l', 'lt', 't', 'rt' ]
        return val.every((v) => possibleValues.includes(v))
      },
      type: Array as PropType<string[]>
    },
    fitParent: {
      default: false,
      type: Boolean
    },
    dragSelector: {
      default: undefined,
      type: String
    },
    maximize: {
      default: false,
      type: Boolean
    },
    disableAttributes: {
      default: () => [],
      validator: (val: string[]) => {
        const possibleValues = [ 'l', 't', 'w', 'h' ]
        return val.every((v) => possibleValues.includes(v))
      },
      type: Array
    }
  },
  emits: [
    'mount',
    'destroy',
    'resize:start',
    'resize:move',
    'resize:end',
    'drag:start',
    'drag:move',
    'drag:end',
    'maximize'
  ],
  setup (props, ctx) {
    const data = reactive({
      w: props.width as number,
      h: props.height as number,
      minW: props.minWidth as number,
      minH: props.minHeight as number,
      maxW: props.maxWidth as number,
      maxH: props.maxHeight as number,
      l: props.left as number,
      t: props.top as number,
      mouseX: 0,
      mouseY: 0,
      offsetX: 0,
      offsetY: 0,
      parent: { width: 0, height: 0 },
      resizeState: 0,
      dragElements: [] as HTMLElement[],
      dragState: false,
      calcMap: 0b1111
    })

    const parent = ref(null) as unknown as Ref<HTMLDivElement>

    const style = computed(() => {
      return {
        ...(data.calcMap & CALC_MASK.w && {
          width: typeof data.w === 'number' ? data.w + 'px' : data.w
        }),
        ...(data.calcMap & CALC_MASK.h && {
          height: typeof data.h === 'number' ? data.h + 'px' : data.h
        }),
        ...(data.calcMap & CALC_MASK.l && {
          left: typeof data.l === 'number' ? data.l + 'px' : data.l
        }),
        ...(data.calcMap & CALC_MASK.t && {
          top: typeof data.t === 'number' ? data.t + 'px' : data.t
        })
      }
    })

    watch(() => props.maxWidth, (value) => {
      data.maxW = value as number
    })
    watch(() => props.maxHeight, (value) => {
      data.maxH = value as number
    })
    watch(() => props.minWidth, (value) => {
      data.minW = value
    })
    watch(() => props.minHeight, (value) => {
      data.minH = value
    })
    watch(() => props.width, (value) => {
      typeof value === 'number' && (data.w = value)
    })
    watch(() => props.height, (value) => {
      typeof value === 'number' && (data.h = value)
    })
    watch(() => props.left, (value) => {
      typeof value === 'number' && (data.l = value)
    })
    watch(() => props.top, (value) => {
      typeof value === 'number' && (data.t = value)
    })
    watch(() => props.dragSelector, (selector) => {
      setupDragElements(selector as string)
    })
    watch(() => props.maximize, (value) => {
      setMaximize(value)
      emitEvent('maximize', { state: value })
    })

    onMounted(() => {
      if (!props.width) {
        data.w = parent.value.parentElement!.clientWidth
      } else if (props.width !== 'auto') {
        typeof props.width !== 'number' && (data.w = parent.value.clientWidth)
      }
      if (!props.height) {
        data.h = parent.value.parentElement!.clientHeight
      } else if (props.height !== 'auto') {
        typeof props.height !== 'number' && (data.h = parent.value.clientHeight)
      }
      typeof props.left !== 'number'
      && (data.l = parent.value.offsetLeft - (parent.value.parentElement!.offsetLeft))
      typeof props.top !== 'number'
      && (data.t = parent.value.offsetTop - (parent.value.parentElement!.offsetTop))
      data.minW && data.w < data.minW && (data.w = data.minW)
      data.minH && data.h < data.minH && (data.h = data.minH)
      data.maxW && data.w > data.maxW && (data.w = data.maxW)
      data.maxH && data.h > data.maxH && (data.h = data.maxH)

      setMaximize(props.maximize)
      setupDragElements(props.dragSelector as string)

      props.disableAttributes.forEach(attr => {
        switch (attr) {
          case 'l':
            data.calcMap &= ~CALC_MASK.l
            break
          case 't':
            data.calcMap &= ~CALC_MASK.t
            break
          case 'w':
            data.calcMap &= ~CALC_MASK.w
            break
          case 'h':
            data.calcMap &= ~CALC_MASK.h
        }
      })

      parent.value.addEventListener(
        'pointermove',
        handleMove,
        true
      )
      parent.value.addEventListener(
        'pointerdown',
        handleDown,
        true
      )
      parent.value.addEventListener('pointerup', handleUp, true)

      emitEvent('mount')
    })

    onBeforeUnmount(() => {
      parent.value.removeEventListener(
        'pointermove',
        handleMove,
        true
      )
      parent.value.removeEventListener(
        'pointerdown',
        handleDown,
        true
      )
      parent.value.removeEventListener(
        'pointerup',
        handleUp,
        true
      )

      emitEvent('destroy')
    })

    let prevState: {w: number, h: number, l: number, t: number} | undefined

    const setMaximize = (value: boolean) => {
      const parentEl = parent.value.parentElement
      if (value) {
        prevState = { w: +data.w, h: +data.h, l: +data.l, t: +data.t }
        data.t = data.l = 0
        data.w = parentEl!.clientWidth
        data.h = parentEl!.clientHeight
      } else {
        restoreSize()
      }
    }

    const restoreSize = () => {
      if (prevState) {
        data.l = prevState.l
        data.t = prevState.t
        data.h = prevState.h
        data.w = prevState.w
      }
    }

    const setupDragElements = (selector: string) => {
      const oldList = parent.value.querySelectorAll('.drag-el')
      oldList.forEach(el => {
        el.classList.remove('drag-el')
      })

      const nodeList = parent.value.querySelectorAll(selector)
      nodeList.forEach(el => {
        el.classList.add('drag-el')
      })
      data.dragElements = Array.prototype.slice.call(nodeList)
    }

    const emitEvent = (eventName: any, additionalOptions: Record<string, unknown> = {}) => {
      ctx.emit(eventName, {
        eventName,
        left: data.l,
        top: data.t,
        width: data.w,
        height: data.h,
        ...additionalOptions
      })
    }

    const handleMove = (event: PointerEvent) => {
      if (data.resizeState !== 0) {
        if (!data.dragState) {
          if (isNaN(data.w as number)) {
            data.w = parent.value.clientWidth
          }
          if (isNaN(data.h as number)) {
            data.h = parent.value.clientHeight
          }
        }

        const eventY = event.clientY
        const eventX = event.clientX

        if (props.maximize && prevState) {
          const parentWidth = data.parent.width
          const parentHeight = data.parent.height
          restoreSize()
          prevState = undefined
          data.t = eventY > parentHeight / 2 ? parentHeight - +data.h : 0
          data.l = eventX > parentWidth / 2 ? parentWidth - +data.w : 0
          emitEvent('maximize', { state: false })
        }
        let diffX = eventX - data.mouseX + data.offsetX
        let diffY = eventY - data.mouseY + data.offsetY
        if (parent.value.getBoundingClientRect) {
          const rect = parent.value.getBoundingClientRect()
          const scaleX = rect.width / parent.value.offsetWidth
          const scaleY = rect.height / parent.value.offsetHeight
          diffX /= scaleX
          diffY /= scaleY
        }
        data.offsetX = data.offsetY = 0
        if (data.resizeState & ELEMENT_MASK['resizable-r'].bit) {
          if (!data.dragState && +data.w + diffX < data.minW) { data.offsetX = diffX - (diffX = data.minW - +data.w) } else if (
            !data.dragState
            && data.maxW
            && +data.w + diffX > data.maxW
            && (!props.fitParent || +data.w + +data.l < data.parent.width)
          ) { data.offsetX = diffX - (diffX = data.maxW - +data.w) } else if (
            props.fitParent
            && +data.l + +data.w + diffX > data.parent.width
          ) {
            data.offsetX
              = diffX - (diffX = data.parent.width - +data.l - +data.w)
          }

          data.calcMap & CALC_MASK.w && ((data.w as number) += data.dragState ? 0 : diffX)
        }
        if (data.resizeState & ELEMENT_MASK['resizable-b'].bit) {
          if (!data.dragState && +data.h + diffY < data.minH) { data.offsetY = diffY - (diffY = data.minH - +data.h) } else if (
            !data.dragState
            && data.maxH
            && +data.h + diffY > data.maxH
            && (!props.fitParent || +data.h + +data.t < data.parent.height)
          ) { data.offsetY = diffY - (diffY = data.maxH - +data.h) } else if (
            props.fitParent
            && +data.t + +data.h + diffY > data.parent.height
          ) {
            data.offsetY
              = diffY - (diffY = data.parent.height - +data.t - +data.h)
          }

          data.calcMap & CALC_MASK.h && ((data.h as number) += data.dragState ? 0 : diffY)
        }
        if (data.resizeState & ELEMENT_MASK['resizable-l'].bit) {
          if (!data.dragState && +data.w - diffX < data.minW) { data.offsetX = diffX - (diffX = +data.w - data.minW) } else if (
            !data.dragState
            && data.maxW
            && +data.w - diffX > data.maxW
            && data.l >= 0
          ) { data.offsetX = diffX - (diffX = +data.w - data.maxW) } else if (props.fitParent && +data.l + diffX < 0) { data.offsetX = diffX - (diffX = -data.l) }

          data.calcMap & CALC_MASK.l && ((data.l as number) += diffX)
          data.calcMap & CALC_MASK.w && ((data.w as number) -= data.dragState ? 0 : diffX)
        }
        if (data.resizeState & ELEMENT_MASK['resizable-t'].bit) {
          if (!data.dragState && +data.h - diffY < data.minH) { data.offsetY = diffY - (diffY = +data.h - data.minH) } else if (
            !data.dragState
            && data.maxH
            && +data.h - diffY > data.maxH
            && data.t >= 0
          ) { data.offsetY = diffY - (diffY = +data.h - data.maxH) } else if (props.fitParent && +data.t + diffY < 0) { data.offsetY = diffY - (diffY = -data.t) }

          data.calcMap & CALC_MASK.t && ((data.t as number) += diffY)
          data.calcMap & CALC_MASK.h && ((data.h as number) -= data.dragState ? 0 : diffY)
        }
        data.mouseX = eventX
        data.mouseY = eventY
        const eventName = !data.dragState ? 'resize:move' : 'drag:move'
        emitEvent(eventName)
      }
    }

    const handleDown = (event: PointerEvent) => {
      const target = event.target as HTMLElement

      if ((target as HTMLElement).closest && target.closest('.resizable-component') !== parent.value) { return }

      parent.value.setPointerCapture(event.pointerId)

      for (const elClass in ELEMENT_MASK) {
        if (
          parent.value.contains(target)
          && ((target.closest && target.closest(`.${elClass}`))
            || target.classList.contains(elClass))
        ) {
          elClass === 'drag-el' && (data.dragState = true)
          document.body.style.cursor = ELEMENT_MASK[elClass as keyof typeof ELEMENT_MASK].cursor

          event.preventDefault && event.preventDefault()
          data.mouseX = event.clientX
          data.mouseY = event.clientY

          data.offsetX = data.offsetY = 0
          data.resizeState = ELEMENT_MASK[elClass as keyof typeof ELEMENT_MASK].bit
          data.parent.height = parent.value.parentElement!.clientHeight
          data.parent.width = parent.value.parentElement!.clientWidth
          const eventName = !data.dragState ? 'resize:start' : 'drag:start'
          emitEvent(eventName)
          break
        }
      }
    }

    const handleUp = (event: PointerEvent) => {
      parent.value.setPointerCapture(event.pointerId)

      if (data.resizeState !== 0) {
        data.resizeState = 0
        document.body.style.cursor = ''
        const eventName = !data.dragState ? 'resize:end' : 'drag:end'
        emitEvent(eventName)
        data.dragState = false
      }
    }

    return {
      ...toRefs(data),
      style,
      parent
    }
  }

})
</script>

<style scoped>
.resizable-component {
  position: relative;
}

.resizable-component > .resizable-r {
  display: block;
  position: absolute;
  z-index: 90;
  touch-action: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: e-resize;
  width: 12px;
  right: -6px;
  top: 0;
  height: 100%;
}

.resizable-component > .resizable-rb {
  display: block;
  position: absolute;
  touch-action: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: se-resize;
  width: 12px;
  height: 12px;
  right: -6px;
  bottom: -6px;
  z-index: 91;
}

.resizable-component > .resizable-b {
  display: block;
  position: absolute;
  z-index: 90;
  touch-action: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: s-resize;
  height: 12px;
  bottom: -6px;
  width: 100%;
  left: 0;
}

.resizable-component > .resizable-lb {
  display: block;
  position: absolute;
  touch-action: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: sw-resize;
  width: 12px;
  height: 12px;
  left: -6px;
  bottom: -6px;
  z-index: 91;
}

.resizable-component > .resizable-l {
  display: block;
  position: absolute;
  z-index: 90;
  touch-action: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: w-resize;
  width: 12px;
  left: -6px;
  height: 100%;
  top: 0;
}

.resizable-component > .resizable-lt {
  display: block;
  position: absolute;
  touch-action: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: nw-resize;
  width: 12px;
  height: 12px;
  left: -6px;
  top: -6px;
  z-index: 91;
}

.resizable-component > .resizable-t {
  display: block;
  position: absolute;
  z-index: 90;
  touch-action: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: n-resize;
  height: 12px;
  top: -6px;
  width: 100%;
  left: 0;
}

.resizable-component > .resizable-rt {
  display: block;
  position: absolute;
  touch-action: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: ne-resize;
  width: 12px;
  height: 12px;
  right: -6px;
  top: -6px;
  z-index: 91;
}
</style>

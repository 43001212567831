<template>
  <div
    class="room"
    :class="{ active }"
    @drop="onDrop($event, room.name), ($event.target as HTMLDivElement).classList.remove('dragover')"
    @dragenter.prevent="($event.target as HTMLDivElement).classList.add('dragover')"
    @dragleave.prevent="($event.target as HTMLDivElement).classList.remove('dragover')"
    @dragover.prevent
  >
    <div>
      <i class="bx" :class="showRoom ? 'bxs-minus-square' : 'bxs-plus-square'" @click="showRoom = !showRoom" />
      <span @dblclick="onSwitchRoom(room.name)" @contextmenu.prevent.stop="onContextMenuShowRoom($event, room.name)">
        {{ room.displayName }}
      </span>
      <b v-if="room.sortedUsers.length > 0">({{ room.sortedUsers.length }})</b>
      <i v-if="room.name !== MAIN_ROOM" class="bx bx-x" @click="closeRoom(room)" />
    </div>
    <ul v-if="room.users.length" v-show="showRoom">
      <template v-for="user in room.sortedUsers" :key="user.name">
        <li
          :class="{
            //me: user === room.getLocalUser(),
            hand: user.hand,
            teacher: user.role === 'teacher',
            inRoom: room.name !== MAIN_ROOM,
            selected: user.selected,
            invisible: !user.visible,
          }"
          :draggable="app.isTeacher() || (user === room.getLocalUser() && user.permissions.rooms) ? true : undefined"
          style="position: relative;"
          @dragstart="
            app.isTeacher() || (user === room.getLocalUser() && user.permissions.rooms) ? startDrag($event, user) : undefined
          "
          @contextmenu.prevent.stop="onContextMenuShowUser($event, user)"
          @dblclick="clearAllStatusIndicators(user.id)"
          @click="app.isTeacher() ? selectUser($event, user) : undefined"
        >
          <span
            v-if="app.isTeacher() && user.role !== 'teacher'"
            :class="user.participationCount === 0 ? 'participation-count-zero' : 'participation-count-some'"
            title="Participation Count: Click to add; Shift+click to take away"
            style="position: absolute; left: -16px; top: 1px"
            @click.exact="app.updateParticipation(1, user.id)"
            @click.shift.exact="app.updateParticipation(-1, user.id)"
          >
            {{ user.participationCount }}
          </span>
          <span :style="{ visibility: user.hand ? 'visible' : 'hidden' }">{{ getHandIndex(user) }}✋</span>
          <span>{{ user.emoji }}</span>
          <span>{{ user.userStatus }}</span>
          <!--<span style="border-radius: 100%; width: 8px; height: 8px; border: 1px solid black; margin-right: 2px;" :style="{background: `hsl(${user.connectionQuality}, 100%, 50%)`}" /> -->
          <!--<span><span :style="{color: `hsl(${user.connectionQuality}, 100%, 50%)`}">{{ user.name + ' ' + user.connectionQuality}}</span></span>-->
          <!--NOTE: I'm not sure if this will work because I don't know if there's a listener to adjust automatically based on user.connectionQuality -->

          <span :style="user === room.getLocalUser() ? 'font-weight: bold' : ''">
            <span style="margin-right: 5px" :style="getUserStyle(user)" :class="getUserClass(user)">
              {{ user.name }}
            </span>
          </span>

          <!-- <span v-if="user.connectionQuality >= 75 && user.userStatus === '⏳'"><span style="margin-right: 5px; opacity: 0.4;">{{ user.name }}</span></span>
        <span v-else-if="user.connectionQuality >= 75 && user.userStatus !== '⏳'"><span style="margin-right: 5px;">{{ user.name }}</span></span>
        <span
          v-else-if="user.connectionQuality < 75 && user.userStatus === '⏳'"
          :style="{color: `hsl(${user.connectionQuality}, 100%, 50%)`}"
        ><span style="margin-right: 5px; opacity: 0.4;">{{ user.name }}</span></span>
        <span
          v-else
          :style="{color: `hsl(${user.connectionQuality}, 100%, 50%)`}"
        ><span style="margin-right: 5px;">{{ user.name }}</span></span> -->

          <span v-show="user.permissions.audio || user.role === 'teacher'" style="margin-left: auto">
            <i
              :class="
                getPermissionClass(
                  'audio',
                  'bx bxs-microphone',
                  user.permissions.audio || user.role === 'teacher',
                  user.isOnAudio,
                  user.audioLevel,
                )
              "
            />
          </span>
          <span v-show="user.permissions.video || user.role === 'teacher'">
            <i
              :class="
                getPermissionClass('video', 'bx bxs-video', user.permissions.video || user.role === 'teacher', user.isOnCamera, 0)
              "
            />
          </span>
          <span v-show="user.permissions.chat || user.role === 'teacher'">
            <i
              :class="
                getPermissionClass(
                  'chat',
                  'bx bxs-message-rounded-check',
                  user.permissions.chat || user.role === 'teacher',
                  user.isTyping,
                  0,
                )
              "
            />
          </span>
          <span v-show="user.permissions.whiteboard || user.role === 'teacher'">
            <i
              :class="
                getPermissionClass(
                  'whiteboard',
                  'bx bxs-edit-alt',
                  user.permissions.whiteboard || user.role === 'teacher',
                  user.isDrawing,
                  0,
                )
              "
            />
          </span>
          <span v-show="user.permissions.screenshare || user.role === 'teacher'">
            <i
              :class="
                getPermissionClass(
                  'screenshare',
                  'bx bx-outline',
                  user.permissions.screenshare || user.role === 'teacher',
                  user.isOnScreenShare,
                  0,
                )
              "
            />
          </span>
          <span v-show="user.permissions.topMenuControl">
            <i :class="getPermissionClass('topMenuControl', 'bx bx-crown', false, user.topMenuControl, 0)" />
          </span>
          <span style="margin-right: 2px">
            <span v-show="showFeedback(room.getLocalUserId(), user.id)">
              <span v-html="formatUserFeedback(user)"></span>
            </span>
          </span>
        </li>
      </template>
    </ul>
  </div>

  <Teleport v-if="showUserContextMenu" to="body">
    <ul :style="contextMenuPosition" class="contextmenu" @click="onContextMenuUserClick">
      <template v-if="app.isTeacher()">
        <li v-for="(value, key) in permissions" :key="key">
          <label>
            <input
              v-model="contextUserModel.permissions[key]"
              type="checkbox"
              @click="setUsersPermission(key, ($event.target as HTMLInputElement).checked)"
            />
            <span v-html="value[1]" />
            {{ value[0] }}
          </label>
        </li>
      </template>
      <li v-if="app.isTeacher()" class="context-menu-divider" />
      <li v-for="item in contextMenuItemsUserActions" :key="item.join()">
        <span v-html="item[0]" />
        <span v-html="item[1]" />
      </li>
      <li v-if="app.isTeacher()" class="context-menu-divider" />
      <li v-for="item in contextMenuItemsUserInfo" :key="item.join()">
        <span v-html="item[0]" />
        <span>{{ item[1] }}</span>
      </li>
    </ul>
  </Teleport>

  <Teleport v-if="showRoomContextMenu" to="body">
    <ul :style="contextMenuPosition" class="contextmenu" @click="onContextMenuRoomClick">
      <template v-if="app.isTeacher()">
        <li v-for="item in contextMenuItemsRoom" :key="item" v-html="item" />
      </template>
    </ul>
  </Teleport>
</template>

<style lang="scss" scoped>
@import '../main.scss';

div.room {
  padding: 6px;
  background: white;

  &:first-child {
    padding-top: 12px;
  }

  &.active {
    background: $room-highlight;
  }

  &.dragover {
    box-shadow: 0 0 10px inset #ddd;

    > * {
      pointer-events: none;
    }
  }

  > div {
    font-size: 14px;
    display: flex;
    align-items: center;

    > span {
      font-weight: bold;
      margin-left: 9px;
      flex-grow: 1;
    }

    > i {
      cursor: pointer;
    }
  }

  > ul {
    margin: 0;
    padding: 0 0 0 15px;
    list-style: none;
    display: flex;
    flex-direction: column;
    // counter-reset: hand;

    > li {
      display: flex;
      align-items: center;
      gap: 2px;

      &.me {
        font-weight: bold;
        // order: -1;

        // > span:nth-child(2) {
        //   > span {
        //     display: none;
        //   }

        //   &::before {
        //     content: 'Me'
        //   }
        // }
      }

      // &.active {
      //   box-shadow: inset 0 0 3px black;
      // }

      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }

      &.hand {
        background: $user-highlight;

        // > span:first-child:before {
        //   counter-increment: hand;
        //   content: counter(hand);
        // }
      }

      &.teacher + :not(.teacher):not(.inRoom) {
        border-top: 1px solid black;
      }

      &.selected {
        background: $icon-highlight;
      }

      &.invisible {
        background: gray;
      }
    }
  }
}

.contextmenu {
  position: absolute;
  background: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px 0;
  margin: 0;
  list-style: none;
  z-index: 10; /* Ensuring menus open on top of the whiteboard / displayarea <div> */

  > li {
    padding: 5px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 3px;

    &:hover {
      background: #ddd;
    }

    > span {
      display: flex;
      align-items: center;
      &:first-child {
        min-width: 20px;
      }
    }
  }
}

.inUse {
  opacity: 1;
  border-radius: 100%;
}

.faded {
  opacity: 0.4;
}

.pulse {
  animation: pulse-effect 3s ease-in-out infinite;
}

@keyframes pulse-effect {
  0% {
    //transform: scale(0.75);
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.5);
  }

  25% {
    // transform: scale(0.90);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0);
  }

  50% {
    // transform: scale(1.1);
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.5);
  }

  75% {
    // transform: scale(0.90);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0);
  }

  100% {
    //  transform: scale(0.75);
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.5);
  }
}

.context-menu-divider {
  border-top: 1px solid;
  margin: 10px;
  padding: 0px !important;
}

.fadeBackground {
  animation: fadeBackground 5s ease-out; // setTimeout() in app.ts has a timer that works with this.
  border-radius: 3px;
  padding-left: 5px; // For giving "background padding"
  padding-right: 5px; // For giving "background padding"
  left: -5px; // So the name doesn't shift to the right 5px.
  position: relative; // So the name doesn't shift to the right 5px.
}

@keyframes fadeBackground {
  from {
    background-color: red;
  }

  to {
    text-shadow: 0 0 10px #fff, 0 0 15px rgb(172, 54, 54), 0 0 20px red, 0 0 25px red;
    background-color: white;
  }
}

.participation-count-zero {
  cursor: pointer;
  background: red;
  border-radius: 50%;
  line-height: 11px;
  vertical-align: middle;
  text-align: center;
  border: 2px solid red;
  width: 12px;
  height: 12px;
  font-size: 11px;
  color: white;
  left: 5px;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
}

.participation-count-some {
  cursor: pointer;
  background: green;
  border-radius: 50%;
  line-height: 11px;
  vertical-align: middle;
  text-align: center;
  border: 2px solid green;
  width: 12px;
  height: 12px;
  font-size: 11px;
  color: white;
  left: 5px;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
}

.confettiUserName {
  animation: confettiUserName 1s ease-in-out infinite; // setTimeout() in app.ts has a timer that works with this.
  border-radius: 3px;
  padding-left: 5px; // For giving "background padding"
  padding-right: 5px; // For giving "background padding"
  //left: -5px; // So the name doesn't shift to the right 5px.
  position: relative; // So the name doesn't shift to the right 5px.
}

@keyframes confettiUserName {
  0% {
    left: -10px;
    background-color: yellow;
    text-shadow: 0 0 10px #fff;
  }

  25% {
    left: 0px;
    text-shadow: 0 0 15px rgb(202, 182, 66);
  }

  50% {
    left: 10px;
    text-shadow: 0 0 20px yellow;
  }

  75% {
    left: 0px;
    text-shadow: 0 0 15px rgb(202, 182, 66);
  }

  100% {
    left: -10px;
    text-shadow: 0 0 10px #fff;
  }
}

.is-cloaked {
  font-style: italic;
  background: black;
  color: white;
  padding-left: 3px;
  padding-right: 3px;
}


</style>


<script lang="ts">
import { computed, defineComponent, Ref, ref, nextTick, onBeforeUnmount } from 'vue';
import { getApp } from '../models/App';
import { Room } from '../models/Room';
import { permissions, Permissions, MAIN_ROOM } from '../types';
import { User } from '../models/User';
import { confirm, alert, prompt } from '../utils';
import { MAX_ROOM_NAME_LENGTH } from '../config';
import { whiteBoardAPI } from './whiteboardAPI';

export default defineComponent({
  name: 'Room',
  props: { name: { type: String, required: true } },
  setup(props) {
    const app = getApp();
    const room = computed(() => Room.get(props.name) as Room);
    const active = computed(() => app.connection.currentRoom === room.value);
    const showRoom = ref(true);

    const startDrag = (ev: DragEvent, user: User) => {
      if (ev.dataTransfer) {
        ev.dataTransfer.dropEffect = 'move';
        ev.dataTransfer.effectAllowed = 'move';

        // Make sure that dragged user is selected
        if (!user.selected) {
          selectUser(ev, user);
        }

        // Students cant select users. Thats why we use "|| user.id" as fallback
        const users =
          User.all()
            .filter((u) => u.selected)
            .map((u) => u.id)
            .join(',') || user.id;
        ev.dataTransfer.setData('text/plain', users);
      }
    };

    const onDrop = (ev: DragEvent, name: string) => {
      const ids = ev.dataTransfer?.getData('text/plain') as string;
      const idArray = ids?.split(',') ?? [];

      const user = User.get(app.connection.id);

      if (!user) return;

      if (user.role === 'teacher' || (idArray.length === 1 && idArray[0] === user.id && user.permissions.rooms)) {
        idArray.forEach((id) => {
          // Deselect user on drop
          User.get(id)!.selected = false;
          app.send(`[switch]${id}:${name}`);
        });
      }
    };

    const contextMenuPosition = ref({});

    const showUserContextMenu = ref(false);
    const contextUser = ref('');

    const contextUserModel = computed(() => User.get(contextUser.value) as User);

    const showRoomContextMenu = ref(false);
    const contextRoom = ref('');

    const onContextMenuShowRoom = (ev: MouseEvent, room: string) => {
      contextRoom.value = room;

      setupContextMenuListeners(showRoomContextMenu);
      setContextMenuPosition(ev);
    };

    const onContextMenuShowUser = (ev: MouseEvent, user: User) => {
      const id = user.id;
      if (id === app.connection.id) {
        // return  // Commenting this out temporarily to allow the displaying of jitsi IDs for teachers
      }

      contextUser.value = id;
      if (!user.selected) {
        selectUser(ev, user);
      }

      setupContextMenuListeners(showUserContextMenu);
      setContextMenuPosition(ev);
    };

    const setContextMenuPosition = (ev: MouseEvent) => {
      contextMenuPosition.value = {
        top: ev.clientY + 'px',
        left: ev.clientX + 'px',
      };
    };

    const setupContextMenuListeners = (showContextMenu: Ref<boolean>) => {
      showContextMenu.value = true;

      const onClick = (ev: MouseEvent) => {
        if ((ev.target as HTMLElement).closest('.contextmenu') !== null) {
          return;
        }

        document.removeEventListener('click', onClick, { capture: true });

        // Upon closing the context menu, Deselect the user(s) that were selected
        const user = User.get(contextUser.value) as User;
        const users = User.all().filter((u) => u.selected);
        if (user !== null) {
          users.forEach((user) => {
            user.selected = false;
          });
        }

        showContextMenu.value = false;
      };

      document.addEventListener('click', onClick, { capture: true });
    };

    const onContextMenuUserClick = (ev: MouseEvent) => {
      const item = (ev.target as HTMLElement).closest('li')?.children[1]!.textContent;
      // const item = ev.target._value

      const user = User.get(contextUser.value) as User;
      const users = User.all().filter((u) => u.selected);
      const userIds = users.map((u) => u.id).join(',');
      const conf = app.connection.currentRoom;

      if ((!user && !users.length) || !conf) return;
      switch (item) {
        case 'Lower Hand':
          users.forEach((user) => {
            app.raiseHand(user.id, false);

            // We don't want the user(s) to remain selected after this action is done.
            user.selected = false;
          });
          break;
        case 'Private Message User':
          app.currentPrivateChat = user.id;
          app.currentChat = user.id;

          // We don't want the user(s) to remain selected after this action is done.
          user.selected = false;

          break;
        case 'Kick User':
          // Make sure we don't accidentally kick a user
          confirm(
            `Are you sure you want to kick ${users.map((u) => u.name).join(', ')}?`,
            (yesOrNo) => {
              if (yesOrNo) {
                users.forEach((user) => {
                  app.restrictedSend('[kick]' + user.id);
                });
              }
            },
            true,
          );
          break;
        case 'Send to Hallway': {
          // We don't want the user(s) to remain selected after this action is done.
          users.forEach((user) => {
            user.selected = false;
          });

          app.send(`[breakout]create:Hallway:1:yes:false:${userIds}`);
          break;
        }
        case 'Reset User Whiteboard': {
          users.forEach((user) => {
            app.restrictedSend(`[WhiteboardReset]${user.id}`);
          });
          break;
        }
        case 'Reset My Whiteboard': {
          whiteBoardAPI.reset(app.connection.currentRoom!.serverName);
          break;
        }
        case 'Confetti': {
          users.forEach((user) => {
            app.restrictedSend(`[confetti]${user.id}`);
          });
          break;
        }
      }

      nextTick(() => {
        showUserContextMenu.value = false;
      });
    };

    const contextMenuItemsUserActions = computed(() => {
      const items: string[][] = [];

      const user = User.get(contextUser.value);
      if (!user) return items;

      // Only let teachers have all abilities to manage users on right click
      if (app.isTeacher()) {
        items.push(['✋', 'Lower Hand']);
        items.push(['<i class="bx bxs-user-x" style="font-size: 18px; vertical-align: middle;"></i>', 'Kick User']);
        items.push(['<i class="bx bxs-door-open" style="font-size: 18px; vertical-align: middle;"></i>', 'Send to Hallway']);
        items.push(['<i class="bx bxs-pencil" style="font-size: 18px; vertical-align: middle;"></i>', 'Reset User Whiteboard']);
        items.push(['<i class="bx bxs-party" style="font-size: 18px; vertical-align: middle;"></i>', 'Confetti']);
      }

      if (user.id !== getApp().connection.id) {
        items.push([
          '<i class="bx bxs-message-alt-check" style="font-size: 18px; vertical-align: middle;"></i>',
          'Private Message User',
        ]);
      } else {
        if (!app.isTeacher()) {
          items.push([
            '<i class="bx bxs-pencil" style="font-size: 18px; vertical-align: middle;"></i>',
            '<span title="Useful for if you end up on a different board, can\'t see something on the board, etc.">Reset My Whiteboard</span>',
          ]);
        }
      }

      return items;
    });

    const contextMenuItemsUserInfo = computed(() => {
      const items: string[][] = [];

      const user = User.get(contextUser.value);
      if (!user) return items;

      // Let teachers...
      if (app.isTeacher()) {
        // See the amount of time that a user has been marked as away and absent for during a session.
        const currentAwayTime = user.awayTime + (user.awayTimeStamp ? nowRef.value - user.awayTimeStamp : 0);
        const currentAbsentTime = user.absentTime + (user.absentTimeStamp ? nowRef.value - user.absentTimeStamp : 0);
        const awayDate = new Date(currentAwayTime);
        const absentDate = new Date(currentAbsentTime);

        items.push([
          '⏳',
          `Time Away: ${awayDate.getMinutes().toString().padStart(1, '0')}:${awayDate.getSeconds().toString().padStart(2, '0')}`,
        ]);
        items.push([
          '<i class="bx bxs-timer" style="font-size: 18px; vertical-align: middle;"></i>',
          `Time Absent: ${absentDate.getMinutes().toString().padStart(1, '0')}:${absentDate
            .getSeconds()
            .toString()
            .padStart(2, '0')}`,
        ]);
        items.push([
          '<i class="bx bxs-user-x" style="font-size: 18px; vertical-align: middle;"></i>',
          `Times Kicked: ${user.kickedCount}`,
          '',
        ]);

        items.push(['<i class="bx bxs-user" style="font-size: 18px; vertical-align: middle;"></i>', 'User ID: ' + user.id]); // See the user's id on right click for debugging purposes
        items.push(['<i class="bx bxs-user" style="font-size: 18px; vertical-align: middle;"></i>', 'Full Name: ' + user.fullName]); // See the user's id on right click for debugging purposes
      }

      return items;
    });

    const nowRef = ref(Date.now());
    let nowRefTimeout: ReturnType<typeof setTimeout> | undefined;
    const updateNowRef = () => {
      nowRef.value = Date.now();
      nowRefTimeout = setTimeout(updateNowRef, 1000);
    };

    updateNowRef();

    const onContextMenuRoomClick = async (ev: MouseEvent) => {
      const room = Room.get(contextRoom.value);

      // Make sure the context menu gets closed.
      nextTick(() => {
        showRoomContextMenu.value = false;
      });

      // Ensure we have a room object (or is this a class???)
      if (!room) return;

      // Open the prompt to specify the new room name
      const newRoomName = await prompt(`Rename ${room.displayName} to:`);

      // Make sure something was entered for the new room name.
      if (!newRoomName) return;

      // Do not allow a breakout room to be called "Main Room".
      if (newRoomName === MAIN_ROOM) return;

      // Make sure the new room name doesn't match one of the existing ones.
      const roomDoesExist = app.connection.rooms.some((r) => {
        return newRoomName === r.displayName;
      });
      if (roomDoesExist) {
        alert('Whoops!  A room with that name already exists.  Please try again.');
        return;
      }
      if (newRoomName.length > MAX_ROOM_NAME_LENGTH) {
        alert(`Uh oh! Keep the room name below ${MAX_ROOM_NAME_LENGTH} characters please.`);
        return;
      }

      // Having a / in the room name causes problems with the whiteboard room/naming convention.
      if (newRoomName.includes('/')) {
        alert('Breakout room names cannot contain the "/" character.');
        return;
      }

      // Rename the room
      app.renameRoom(room.name, newRoomName);
    };

    const contextMenuItemsRoom = computed(() => {
      const items: string[] = [];

      const room = Room.get(contextRoom.value);

      if (!room) return items;

      // Only teachers can rename rooms, but they can't rename the Main Room.
      if (app.isTeacher() && room.name !== MAIN_ROOM) {
        items.push('Rename Room');
      }

      return items;
    });

    const closeRoom = (room: Room) => {
      getApp().restrictedSend(`[breakout]close:${room.name}`);
    };

    const onSwitchRoom = (name: string) => {
      const user = User.get(app.connection.id);
      if (user && (user.permissions.rooms || user.role === 'teacher')) {
        app.send(`[switch]${app.connection.id}:${name}`);
      }
    };

    const getUserStyle = (user: User) => {
      return {
        opacity: user.userStatus === '⏳' ? 0.4 : undefined,
        color: user.connectionQuality < 75 ? `hsl(${user.connectionQuality}, 100%, 50%)` : undefined,
      };
    };

    const getUserClass = (user: User) => {
      if (user.justJoined) {
        return 'fadeBackground';
      }

      if (user.confetti) {
        return 'confettiUserName';
      }

      if (user.cloaked) {
        return 'is-cloaked';
      }
      // user.justJoined = false
    };

    // Set the class(es) of a specified permission
    const getPermissionClass = (permission: string, icon: string, canDoIt: boolean, isDoingIt: boolean, audioLevel: number) => {
      if (permission === 'screenshare' || permission === 'video') {
        if (canDoIt && !isDoingIt) {
          return icon + ' faded';
        }
        if (canDoIt && isDoingIt) {
          return icon + ' inUse';
        }
      }

      if (permission === 'audio') {
        if (canDoIt && !isDoingIt) {
          return icon + ' faded';
        }
        if (canDoIt && isDoingIt) {
          if (audioLevel > 0.05) {
            return icon + ' inUse pulse';
          } else {
            return icon + ' inUse';
          }
        }
      }

      if (permission === 'chat' || permission === 'whiteboard') {
        if (canDoIt && !isDoingIt) {
          return icon + ' faded';
        }
        if (canDoIt && isDoingIt) {
          return icon + ' inUse pulse';
        }
      }

      if (permission === 'topMenuControl' || permission === 'cloaked') {
        if (isDoingIt) {
          return icon;
        }
      }
    };

    // Being able to clear out all status indicators upon double clicking a user name
    const clearAllStatusIndicators = (id: string) => {
      // Only teachers and can clear users, and only users can clear themselves
      if (!app.isTeacher() && app.connection.id !== id) return;

      if (app.connection.id === id) {
        app.feedback = '';
        app.userStatus = '';
        app.emoji = '';
      } else {
        app.setUserFeedback(id, '');
        app.setUserEmoji(id, '');
        app.setUserStatus(id, '');
      }
    };

    const sortedUsersWithRaisedHands = computed(() =>
      User.all()
        .filter((user) => user.hand && user.visible)
        .sort((a, b) => a.handTime - b.handTime),
    );

    const getHandIndex = (user: User) => {
      return sortedUsersWithRaisedHands.value.indexOf(user) + 1;
    };

    const showFeedback = (localUserId: string, userId: string) => {
      return app.isTeacher() || app.feedbackVisibility || userId === localUserId;
    };

    const formatUserFeedback = (user: User) => {
      // Format the feedback display based on if it's a feedback rating value or not
      if(Number.isInteger(parseInt(user.feedback))) {
        return '⭐ ' + user.feedback
      } else {
        return user.feedback
      }
    }

    const selectUser = (ev: MouseEvent, user: User) => {
      // Prevent students from selecting people since they can't perform any actions with selected users anyway.
      if (!app.isTeacher()) return;

      // Prevent selection upon right click
      // if (ev.button === 2) return

      if (!ev.ctrlKey) {
        User.all().forEach((u) => {
          u.selected = false;
        });
      }

      user.selected = ev.ctrlKey ? !user.selected : true;
    };

    // For setting permissions for all selected users (not teachers though)
    const setUsersPermission = (key: keyof Permissions, checked: boolean) => {
      // Get all selected users
      const users = User.all().filter((u) => u.selected);

      // Set the permission for each selected user
      users.forEach((user) => {
        app.setUserPermission(user.id, key, checked);
        // app.setUserPermission(contextUser, key, $event.target.checked)
      });

      nextTick(() => {
        showUserContextMenu.value = false;
      });
    };

    // For setting "cloaking" being on or off (this allows teachers to check in on breakout rooms while still appearing as if they're in the main room)
    const isCloaked = (userForCloak: User, roomName: string, userViewing: User) => {
      // Cloaking is only possible when in BO
      if (roomName === MAIN_ROOM) {
        // In main room
        return false;
      } else {
        // In BO
        // if (app.isCloaked() === true && userViewing.role !== 'teacher') {
        if (userForCloak.cloaked === true && userViewing.role !== 'teacher') {
          return true;
        }
      }
    };

    onBeforeUnmount(() => {
      clearTimeout(nowRefTimeout);
    });

    return {
      app,
      room,
      active,
      showRoom,
      closeRoom,
      onSwitchRoom,
      startDrag,
      onDrop,
      showUserContextMenu,
      onContextMenuShowUser,
      contextMenuItemsUserActions,
      contextMenuItemsUserInfo,
      onContextMenuUserClick,
      contextMenuPosition,
      contextUser,
      contextUserModel,
      permissions,
      MAIN_ROOM,
      getUserStyle,
      getUserClass,
      getPermissionClass,
      clearAllStatusIndicators,
      getHandIndex,
      showFeedback,
      selectUser,
      showRoomContextMenu,
      onContextMenuRoomClick,
      onContextMenuShowRoom,
      contextMenuItemsRoom,
      setUsersPermission,
      isCloaked,
      formatUserFeedback
    };
  },
});
</script>

<template>
  <div
    :style="app.isRecorder ? 'height: 225px' : ''"
    class="chat"
  >
    <div class="chat-header">
      <label
        :class="{active: chatIndicator}"
        title="Show Chats"
      >
        <input
          v-model="showChatList"
          type="checkbox"
          hidden
        >
        <i class="bx bxs-message-alt-check" />
      </label>
      <span>CHAT - {{ currentRoom?.name ?? '' }}</span>
      <label
        :title="chatFunnelTitle(app.funnelChat)"
        :class="{chatfunnelactive: app.funnelChat}"
      >
        <i
          v-if="app.isTeacher() || app.funnelChat"
          class="bx bxs-filter-alt"
          :style="app.isTeacher() ? 'cursor: hand' : 'cursor: default'"
          @click="setChatFunneling"
        />
      </label>
      <label :title="app.dockRight ? 'Dock Left' : 'Dock Right'">
        <input
          v-model="app.dockRight"
          type="checkbox"
          hidden
        >
        <i
          class="bx"
          :class="app.dockRight ? 'bxs-arrow-from-right' : 'bxs-arrow-from-left'"
        />
      </label>

      <ul
        v-show="showChatList"
        v-if="app.connection.currentRoom"
        class="chat-list"
      >
        <li
          :class="{active: chatIndicators[app.connection.currentRoom.name] && app.connection.currentRoom !== currentRoom}"
          @click="selectRoom(app.connection.currentRoom!.name)"
        >
          <i class="bx bxs-message-alt-check" /> {{ app.connection.currentRoom.name }}
        </li>
        <li
          v-for="user in usersWithMyMessages"
          :key="'chat-list' + user.id"
          :class="{active: chatIndicators[user.id] && user !== currentRoom}"
          @click="selectRoom(user.id),app.currentPrivateChat = user.id"
        >
          <i class="bx bxs-message-alt-check" /> {{ user.name }}
        </li>
      </ul>
    </div>
    <div class="chat-body">
      <div
        v-chat-scroll="app.isRecorder ? '' : {always: true, smooth: true}"
        class="chat-messages"
      >
        <div
          v-for="chat in messages ?? []"
          :key="chat.from + chat.to + chat.time"
          class="chat-message"
          :class="{local: isLocalChatMessage(chat), private: chat.to && chat.from, funneled: chat.funneled}"
        >
          <div class="chat-message-header">
            <div class="chat-message-header-name">
              {{ getUserName(chat.from) }}
              <span
                v-if="isPrivateChat(chat.to, chat.from)"
                @click="selectRoom(chat.from),app.currentPrivateChat = chat.from"
                v-html="privateChatHeader(chat.to, getUserName(chat.from))"
              />
            </div>
            <div class="chat-message-header-time">
              {{ formatTime(chat.time) }}
            </div>
          </div>
          <div
            class="chat-message-body"
            v-html="formatChatMessage(chat.msg)"
          />
        </div>
      </div>
      <button
        v-if="app.connection.currentRoom && currentRoom?.name !== app.connection.currentRoom.name"
        class="return-to-main-chat-button"
        @click="selectRoom(app.connection.currentRoom!.name)"
      >
        Return to {{ app.connection.currentRoom!.name }} Chat
      </button>
    </div>
    <div
      :class="app.getUser()?.permissions.chat ? '' : 'restricted-chat'"
      class="chat-input"
    >
      <input
        ref="input"
        v-model="message"
        maxlength="300"
        @keydown.enter="send"
        @keydown="userIsTyping"
      >
      <button
        :class="app.getUser()?.permissions.chat ? '' : 'restricted-chat-send'"
        :title="app.getUser()?.permissions.chat ? '' : 'Your chat permission is off, so all chats will only be seen by the teacher(s).'"
        @click="send"
      >
        Send
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../main.scss';

div.chat {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  background: $chat-background;

  > div.chat-header {
    display: flex;
    font-size: 14px;
    height: 26px;
    color: white;
    background: $gray;
    align-items: center;
    padding: 0 6px 0 0;
    position: relative;

    > span {
      flex-grow: 1;
    }

    > i, label {
      cursor: pointer;
      font-size: 20px;
      margin-right: 3px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      &.active {
        background: $icon-highlight;
        color: $icon-highlight;
      }

      &.chatfunnelactive {
        color: red
        // animation: pulse-effect 2s ease-in-out infinite;

        // @keyframes pulse-effect {
        //   0% {
        //     color: rgba(255, 255, 255);
        //   }

        //   25% {
        //     //color: rgb(150, 247, 131);
        //     color: $funneled-message-background;
        //   }

        //   50% {
        //     //color: rgb(63, 196, 1)
        //     color: $funneled-message-color-heading;
        //   }

        //   75% {
        //     //color: rgb(150, 247, 131);
        //     color: $funneled-message-background;
        //   }

        //   100% {
        //     color: rgba(255, 255, 255);
        //   }
        // }

      }
      &:last-child {
        margin: 0
      }

      &:first-child {
        height: 26px;
        width: 26px;
      }
    }

    > ul.chat-list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      background: white;
      position: absolute;
      top: 26px;
      left: 0px;
      color: black;

      > li {
        display: flex;
        align-items: center;
        font-weight: bold;
        padding-right: 20px;
        cursor: pointer;

        &:hover {
          background: $room-highlight;
        }

        + li {
          border-top: 1px solid #f6f7f7;
        }

        > i {
          height:30px;
          width: 30px;
          text-align: center;
          line-height: 30px;
          visibility: hidden;
          color: $icon-highlight;
        }

        &.active > i{
          visibility: visible;
        }
      }
    }

  }

  > div.chat-body {
    display: flex;
    flex-direction: column;
    padding: 5px;
    flex-grow: 1;
    height: 0;

    > div.chat-messages {
      display: flex;
      flex-direction: column;
      overflow: auto;

      > div.chat-message {
        display: flex;
        flex-direction: column;
        margin-top: 5px;
        width: 80%;

        &.private {
          font-style: italic;

          > div.chat-message-header {
            color: $private-message-color-heading;
          }

          > div.chat-message-body {
            background: $private-message-background;
          }
        }

        &.local {
          align-self: flex-end;

          > div.chat-message-header {
            color: $black;
            text-align: right;
            justify-content: flex-end;
          }

          > div.chat-message-body {
            background: $own-message-color;
          }
        }

        &.funneled {
          font-style: italic;

          > div.chat-message-header {
            color: $funneled-message-color-heading;
          }

          > div.chat-message-body {
            background: $funneled-message-background;
            color: white
          }
        }

        > div.chat-message-header {
          display: flex;
          color: $message-color-heading;

          > div.chat-message-header-name {
            font-weight: bold;
            margin-inline-end: 5px;
          }
        }

        > div.chat-message-body {
          background: white;
          color: $message-color;
          border-radius: 5px;
          padding: 5px 10px;
          word-wrap: break-word;
        }
      }
    }
  }

  > div.chat-input {
    display: flex;
    padding: 5px;

    > input {
      border: none;
      padding: 3px 6px;
      margin: 0;
      height: 21px;
      line-height: 21px;
      flex-grow: 1;
    }

    > button {
      border: none;
      line-height: 21px;
      padding: 3px 6px;
      background: $icon-highlight;
      margin-left: 3px;
      color: white;
      font-weight: bold;
      border-radius: 3px;
    }
  }

  .restricted-chat{
    border: 2px solid red
  }

  .restricted-chat-send{
    background: red !important
  }

  .return-to-main-chat-button {
    border: none;
      line-height: 21px;
      padding: 3px 6px;
      background: $icon-highlight;
      margin-left: 3px;
      color: black;
      font-weight: bold;
      border-radius: 3px;
      margin: 5px;
  }
}

</style>

<script lang="ts">
import { computed, isRef, nextTick, ref, watch, defineComponent } from 'vue'
import { getApp } from '../models/App'
import { Room } from '../models/Room'
import { User } from '../models/User'
import { ChatMessage, MAIN_ROOM } from '../types'
export default defineComponent({
  name: 'Chat',
  setup () {
    const app = getApp()
    const input = ref<HTMLInputElement | null>(null)

    const usersWithMessage = computed(() => {
      return User.all().filter((u) => !!u.messages.length || app.currentPrivateChat === u.id)
    })

    const usersWithMyMessages = computed(() => {
      return User.all().filter((u) => !!u.messages.filter(m => m.to === app.getUser()?.id || m.from === app.getUser()?.id).length || app.currentPrivateChat === u.id)
    })

    watch(() => app.currentChat, (newValue, oldValue) => {
      delete app.chatIndicator[newValue]
      delete app.chatIndicator[oldValue]
      nextTick(() => {
        input.value?.focus()
      })
    })

    const chatIndicator = computed(() => {
      const obj = Object.assign({}, app.chatIndicator)

      return Object.entries(obj).find(([ key, val ]) => key !== app.currentChat && val)?.[1] ?? false
      // ?? (obj[app.currentChat] ? app.currentChat : false)
    })

    const chatIndicators = computed(() => app.chatIndicator)

    const currentRoom = computed(() => Room.get(app.currentChat) || User.get(app.currentChat))

    const messages = computed(() => {
      if (currentRoom.value === Room.get(app.currentChat)) {
        // Merge room messages with private messages
        return currentRoom.value?.messages
          .concat(...usersWithMessage.value.map((u) => u.messages))
          .sort((a, b) => a.time - b.time)
      }

      return currentRoom.value?.messages.filter((msg) => {
        return msg.to === app.getUser()?.id || msg.from === app.getUser()?.id
      })
    })

    const getUserName = (id: string) => User.get(id)?.name ?? 'Unknown User'

    const isLocalChatMessage = (chat: ChatMessage) => {
      return app.connection.id === chat.from
    }

    const formatTime = (time: number) => {
      const date = new Date(time)
      return date.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit', second: '2-digit' })
    }

    function createTextLinks (text: string) {
      return (text || '').replace(
        /([^\S]|^)(((https?:\/\/)|(www\.))(\S+))/gi,
        function (match, space, url) {
          let hyperlink = url
          if (!hyperlink.match('^https?://')) {
            hyperlink = 'http://' + hyperlink
          }
          return space + '<a href="' + hyperlink + '" target="_blank">' + url + '</a>'
        }
      )
    }

    function escapeHtml (text: string) {
      const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;'
      } as Record<string, string>

      return text.replace(/[&<>"']/g, function (m: string) { return map[m] })
    }

    const formatChatMessage = (msg: string) => {
      return createTextLinks(escapeHtml(msg))
    }

    const showChatList = ref(false)
    const message = ref('')

    const send = () => {
      if (!message.value.trim()) return
      console.log('trying to send message')

      const conf = app.connection.currentRoom

      if (!app.getUser()?.permissions.chat && !app.isTeacher()) {
        app.send(`[typing]0:${conf?.getLocalUserId()}:${app.currentChat}`)

        User.all().forEach(u => {
          if (u.role === 'teacher' && u.visible) { // Teachers that are present get chats routed to them.
            conf?.sendMessage(message.value, u?.id)
          }
        })
      } else {
        app.send(`[typing]0:${conf?.getLocalUserId()}:${app.currentChat}`)
        conf?.sendMessage(message.value, User.get(app.currentChat)?.id)
      }
      app.getUser().isTyping = false
      message.value = ''

    }

    const selectRoom = (id: string) => {
      app.currentChat = id
      showChatList.value = false
    }

    let isTyping = false
    let typingTimeout = 0
    const userIsTyping = () => {
      const conf = app.connection.currentRoom
      if (!conf) return

      if (isTyping) {
        clearTimeout(typingTimeout)
      } else {
        app.send(`[typing]1:${conf.getLocalUserId()}:${app.currentChat}`)
        app.getUser().isTyping = true
      }

      isTyping = true
      typingTimeout = window.setTimeout(() => {
        isTyping = false
        app.send(`[typing]0:${conf.getLocalUserId()}:${app.currentChat}`)
        app.getUser().isTyping = false
      }, 1000)
    }

    const setChatFunneling = () => {
      // Only allow teachers to turn on chat funneling
      if (!app.isTeacher()) return

      // Set the funneling (or not) of the chat locally.
      app.funnelChat = !app.funnelChat

      // Tell the server the chat is being funneled.
      app.restrictedSend(`[funnelChat]${app.funnelChat}`)
    }

    const chatFunnelTitle = (on: boolean) => {
      if (app.isTeacher() && on) {
        return 'Disable Chat Funneling'
      } else if (app.isTeacher() && !on) {
        return 'Funnel Chat'
      } else {
        return 'Chat is being funneled.'
      }
    }

    const isPrivateChat = (to: string, from: string) => {
      if (to && from && app.currentPrivateChat !== app.currentChat) {
        return true
      }
    }

    const privateChatHeader = (to: string, name: string) => {
      return app.getUser()?.id === to ? `➞ ${getUserName(to)} <i class="bx bxs-message-alt-check" style="cursor: pointer" title="Respond to ${name}">` : `➞ ${getUserName(to)}`
    }

    return {
      app,
      message,
      messages,
      send,
      selectRoom,
      showChatList,
      currentRoom,
      getUserName,
      chatIndicator,
      chatIndicators,
      usersWithMessage,
      isLocalChatMessage,
      formatChatMessage,
      formatTime,
      userIsTyping,
      usersWithMyMessages,
      input,
      setChatFunneling,
      chatFunnelTitle,
      isPrivateChat,
      privateChatHeader
    }
  }
})
</script>

<template>
  <div
    ref="div"
    class="dropdown"
    :class="{active: show}"
  >
    <div
      ref="clicked"
      class="icon"
      @click="show = !show"
    >
      <slot name="icon">
        <i
          class="bx"
          :class="icon"
          :style="{color: color ?? undefined}"
        />
      </slot>
      <!-- <span>{{ label }}</span> -->
    </div>
    <div
      v-show="show"
      class="menu"
    >
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../main.scss';

div.dropdown {
  position: relative;

  > div.icon {
    width: 32px;
    height: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;

    > i, > :deep(svg) {
      color: white;
    }

    &:hover {
      background: rgba(255,255,255,0.5);
    }
  }

  > div.menu {
    position: absolute;
    top: 30px;
    left: 0px;
    z-index: 10;
    font-size: 18px;
    text-align: left;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    background: white;
    cursor: pointer;
    min-width: 150px;

    > :hover {
      background: $room-highlight;
    }

    > :deep(*){
      padding: 5px 2px;
      display: flex;
      align-items: center;
      gap: 4px;

      > i {
        font-size: 20px;
      }
    }

    > :deep(div) {
      &.active {
        background: $light-light-gray;
      }

      &:hover {
        outline: 1px solid black;
      }
    }
  }

  &.active > div:first-child {
    background: $icon-highlight;
    > i {
      color: black;
    }
  }
}
</style>

<script lang="ts">
import { ref, Ref, watch, defineComponent } from 'vue'
export default defineComponent({
  name: 'Dropdown',
  props: { icon: String, noautohide: Boolean, value: Boolean, color: String },
  setup (props) {
    const show = ref(props.value)
    const div = ref(null) as Ref<HTMLDivElement|null>
    const clicked = ref(null) as Ref<HTMLDivElement|null>

    watch(() => props.value, (newValue) => {
      show.value = newValue
    })

    if (!props.noautohide) {
      document.addEventListener('click', (ev) => {
        if (clicked.value?.contains(ev.target as HTMLElement)) return
        show.value = false
      })
    }

    return { show, div, clicked }
  }
})
</script>

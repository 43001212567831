<template>
  <div class="wrapper">
    <transition name="fade">
      <div
        v-if="!currentRoom || currentRoom.joining || !currentRoom.joined || !app.running || !app.connection.connected || app.connection.switching || currentRoom.boardSetLoading || currentRoom.boardSetDeleting"
        class="overlay"
      >
        <div v-text="app.connection.overlayMessage" />
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="infoMsg"
        class="info"
      >
        {{ infoMsg }}
      </div>
    </transition>

    <aside>
      <div
        v-if="!app.isRecorder"
        style="background: black"
      >
        <NameAndControls
          v-model:audio="app.isAudioEnabled"
          v-model:video="app.isVideoEnabled"
          :devices="app.devices"
          :audio-disabled="!currentRoom || app.audioNotPermitted || ((!app.getUser()?.permissions?.audio ?? true) && !app.isTeacher())"
          :video-disabled="!currentRoom || app.videoNotPermitted || ((!app.getUser()?.permissions?.video ?? true) && !app.isTeacher())"
        />
        <InputOutputSlider
          v-model:output="app.volume"
          v-model:input="app.inputGain"
        />
        <StatusIndicator />
      </div>

      <div v-else>
        <VideoBoxDocked v-show="app.showVideo" />
      </div>

      <FeedbackManager v-if="app.isTeacher()" />

      <Permissions v-if="app.isTeacher()" />
      <Rooms />
      <Chat v-if="!app.dockRight" />
    </aside>

    <main ref="main">
      <TopMenu :id="id" />
      <div
        v-if="currentRoom"
        class="displayarea"
      >
        <div style="display: none;">
          <audio
            v-for="track in audioTracks"
            :key="track.getTrackId() + track.getTrackLabel()"
            :ref="(ref) => track && ref && track.attach(ref as HTMLAudioElement)"
            autoplay
            :muted="track.isLocal() ? true : undefined"
            :class="{local: track.isLocal(), audio: true}"
            :volume="app.volume"
          />
        </div>

        <video
          v-if="screenShareTrack"
          v-show="currentRoom?.display ==='screenshare' && !app.isScreenShareEnabled"
          :key="screenShareTrack.getTrackId() + screenShareTrack.getTrackLabel()"
          :ref="(ref) => screenShareTrack && ref && screenShareTrack.attach(ref as HTMLVideoElement)"
          autoplay
          muted
          :class="{
            video: true,
            screenshare: true
          }"
        />

        <iframe
          v-show="currentRoom?.display ==='whiteboard' || app.isScreenShareEnabled"
          ref="whiteBoardRef"
          :src="currentRoom.whiteBoardUrl + '?cache=' + (new Date().getMonth() + 1) + '-' + (new Date().getDate()) + '-' + (new Date().getFullYear()) + '#navigation=false&manual' + (app.isRecorder ? '&readonly=true' : '')"
          class="focus"
          @load="onWhiteBoardLoad"
          allow="clipboard-read; clipboard-write"
        />
      </div>
    </main>

    <aside v-if="app.dockRight">
      <Chat />
    </aside>

    <div v-if="allowFloatableVideo">
      <VideoBox v-show="app.showVideo" />
    </div>
  </div>
  <ConfirmDialog />
  <PromptDialog />
  <AlertDialog />
</template>

<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
}

.info {
  position: fixed;
  top: 20px;
  left: 50%;
  background: rgb(170, 170, 255);
  transform: translateX(-50%);
  border: 1px solid #555;
  border-radius: 5px;
  z-index: 999;
  padding: 10px 20px;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.6);
  z-index: 100;

  > div {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border: 1px solid black;
    box-shadow: 0 0 2px white;
    padding: 10px 20px;
  }
}

aside {
  min-width: 275px;
  flex: 0;
  display: flex;
  flex-direction: column;
  background: none;
  position: relative;
  border-right: 1px solid #949494;
  z-index: 10; /* Ensuring menus open on top of the whiteboard / displayarea <div> */
}

main {
  flex: 1;
  min-width: 340px;
  display: flex;
  flex-direction: column;

  > .displayarea {
    flex: 1;
    overflow: hidden;
    position: relative;

    > video, > iframe {
      max-height: 100%;
      max-width: 100%;
      width: 100%;
      height: 100%;
      margin: auto;
      border: none;
    }
  }
}

</style>

<style>
  .fireWorksText {
    position: absolute;
    color: red;
    font-size: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-100%) translateX(50px); /* Doing translateX(50px) to account for the width of the whiteboard toolbar. */
    animation: blinker 1s step-end infinite;
    min-width: max-content; /* To prevent wrapping of text.  There should be a better solution than this though. */
  }

  @keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>

<script lang="ts">
import { computed, defineComponent, Ref, ref, watch, watchEffect, markRaw } from 'vue'

import { MAIN_ROOM } from '../types'
import { Room } from '../models/Room'
import { whiteBoardRef, onWhiteBoardLoad } from './whiteboardAPI'
import { getApp } from '../models/App'
import NameAndControls from './NameAndControls.vue'
import InputOutputSlider from './InputOutputSlider.vue'
import StatusIndicator from './StatusIndicator.vue'
import Permissions from './Permissions.vue'
import FeedbackManager from './FeedbackManager.vue'
import Rooms from './Rooms.vue'
import Chat from './Chat.vue'
import TopMenu from './TopMenu.vue'
import VideoBox from './VideoBox.vue'
import VideoBoxDocked from './VideoBoxDocked.vue'
import ConfirmDialog from './ConfirmDialog.vue'
import PromptDialog from './PromptDialog.vue'
import AlertDialog from './AlertDialog.vue'

const infoMsg:Ref<string|null> = ref(null)

let infoMsgTimeout = 0
const setInfo = (msg: string) => {
  clearTimeout(infoMsgTimeout)
  infoMsg.value = msg

  infoMsgTimeout = setTimeout(() => {
    infoMsg.value = null
  }, 2000)
}

export default defineComponent({
  name: 'Conference',
  components: {
    NameAndControls,
    InputOutputSlider,
    StatusIndicator,
    Permissions,
    FeedbackManager,
    Rooms,
    Chat,
    TopMenu,
    VideoBox,
    VideoBoxDocked,
    ConfirmDialog,
    PromptDialog,
    AlertDialog
  },
  props: [ 'id' ],
  setup () {
    const app = getApp()
    // app.connection.switch(MAIN_ROOM)
    //   .then(() => {
    //     window.APP.conference._room = app.connection.currentRoom?.conference
    //   })

    watch(() => app.status, (text) => {
      if (text) setInfo(text)
    })

    watch(() => app.isScreenShareEnabled, () => {
      app.connection.currentRoom?.refreshLocalTracks()
    })

    watch(() => app.isAudioEnabled, (newValue) => {
      const conf = app.connection.currentRoom
      if (!conf) return

      if (newValue) {
        conf.refreshLocalTracks().then(() => {
          conf.getLocalAudio()?.unmute()
        })
      } else {
        conf.refreshLocalTracks().then(() => {
          conf.getLocalAudio()?.mute()
        })
      }
    })

    watch(() => app.isVideoEnabled, (newValue) => {
      const conf = app.connection.currentRoom
      if (!conf) return

      if (newValue) {
        conf.refreshLocalTracks().then(() => {
          conf.getLocalVideo()?.unmute()
        })
      } else {
        conf.refreshLocalTracks().then(() => {
          conf.getLocalVideo()?.mute()
        })
      }
    })

    const main = ref<HTMLDivElement|null>(null)

    const toggleFullscreen = (on: boolean) => {
      if (on) {
        main.value?.requestFullscreen()
      } else if (document.fullscreenElement) {
        document.exitFullscreen()
      }
    }

    watch(() => app.fullscreen, (newValue) => {
      toggleFullscreen(newValue)
    })

    const rooms = computed(() => Room.all())
    const currentRoom = computed(() => app.connection.currentRoom)

    const audioTracks = computed(() => app.connection.currentRoom?.tracks.filter((t) => t.getType() === 'audio'))
    const screenShareTrack = computed(() => app.connection.currentRoom?.tracks.find((t) => t.getType() === 'video' && t.getVideoType() === 'desktop'))

    watch(() => app.talkWhileMuted, (val) => {
      if (val) {
        setInfo('You are muted. If you want to talk, enable your microphone')
      }
    })

    watch(() => app.noisyMic, (val) => {
      if (val) {
        setInfo('Your microphone is noisy. Please choose a different one if possible')
      }
    })

    watch(() => app.noAudio, (val) => {
      if (val) {
        setInfo('The selected microphone has no signal. Please make sure to select the correct microphone')
      }
    })

    // Determine if the videobox should be floatable or docked.
    let allowFloatableVideo = true

    // The videobox needs to be docked for recorder beacuse if the videobox is not properly closed for
    // any reason, Recorder cannot move it, whereas users can move it or close it manually in that case.
    if (app.isRecorder) {
      allowFloatableVideo = false
    }

    return {
      onWhiteBoardLoad,
      app,
      audioTracks,
      screenShareTrack,
      rooms,
      currentRoom,
      whiteBoardRef,
      infoMsg,
      main,
      reload: () => window.location.reload(),
      allowFloatableVideo
    }
  }
})

</script>

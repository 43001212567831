import { LOG_LEVEL } from './config';

export const FATAL = 0;
export const ERROR = 1;
export const WARN = 2;
export const INFO = 3;
export const DEBUG = 4;
export type LogLevels = 0 | 1 | 2 | 3 | 4;

const logLevels = ['FATAL', 'ERROR', 'WARN', 'INFO', 'DEBUG'];

export const log = (level: LogLevels, ...args: any[]) => {
  let logFn = console.log;
  switch (level) {
    case FATAL:
    case ERROR:
      logFn = console.error;
      break;
    case WARN:
      logFn = console.warn;
      break;
    case INFO:
      logFn = console.info;
      break;
    case DEBUG:
      logFn = console.debug;
      break;
  }

  if (level <= LOG_LEVEL) {
    logFn(logLevels[level], ...args);
  }
};

<template>
  <!-- <h1>Collaborative Canvas</h1>
  <input
    v-model="room"
    type="text"
    @focus="stopNames"
  >
  <button @click="changeToRoom">
    Join
  </button> -->

  Nothing to see here
</template>

<script lang="ts">
// import nouns from '../nouns.js'
// import adjectives from '../adjectives.js'
// import { useRouter } from 'vue-router'
// import { ref } from 'vue'

// if (!window.location.href.endsWith('kjsadeonwodsknppod')) { window.location.href = '/kjsadeonwodsknppod' }

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Welcome'
  // setup () {
  //   const router = useRouter()

  //   const generateRoomName = () => {
  //     const noun = nouns[~~(Math.random() * nouns.length)]
  //     const adjective = adjectives[~~(Math.random() * adjectives.length)]
  //     return `${adjective} ${noun}`
  //   }

  //   const room = ref(generateRoomName())

  //   const interval = setInterval(() => { room.value = generateRoomName() }, 3000)

  //   const stopNames = (ev) => {
  //     clearInterval(interval)
  //   }

  //   const changeToRoom = () => {
  //     const name = room.value.replace(/\s/g, '_')
  //     router.push(`/${name}`)
  //   }

  //   return { room, stopNames, changeToRoom }
  // }
})
</script>

import { Room } from './models/Room'

export function defaultPermissions () {
  return {
    audio: true,
    video: false,
    chat: true,
    screenshare: false,
    whiteboard: true,
    navigate: true,
    topMenuControl: false,
    rooms: false
  }
}

export const permissions = {
  audio: [ 'Audio', '<i class="bx bxs-microphone" />' ],
  video: [ 'Video', '<i class="bx bxs-video" />' ],
  chat: [ 'Chat', '<i class="bx bxs-message-rounded-check" />' ],
  screenshare: [ 'Screenshare', '<i class="bx bx-outline" />' ],
  whiteboard: [ 'Whiteboard', '<i class="bx bxs-edit-alt" />' ],
  topMenuControl: [ 'WB Navigation', '<i class="bx bxs-crown" />' ]
}

export type Permissions = ReturnType<typeof defaultPermissions>

export type UserInfo = {
  id: string
  jitsiId: string
  name: string
  role: 'student'|'teacher'
  emoji: string
  feedback: string
  userStatus: string
  hand: boolean
  visible: boolean
  permissions: Permissions
  roomId: string
  isOnAudio: boolean
  isOnCamera: boolean
  isOnScreenShare: boolean
  awayTime: number
  awayTimeStamp: number
  absentTime: number
  absentTimeStamp: number
  firstJoinedTime: number
  lastLeftTime: number
}

export type ShortUserInfo = {
  id: string
  jitsiId: string
  visible: boolean
}

export type room = {
  conference?: Room, // eslint-disable-line
  users: string[]
  whiteboardName: string
  currentWhiteboardName: string
}

export type ChatMessage = {
  to: string
  from: string
  msg: string
  time: number
  inc: number
  funneled: boolean
}

export type command = {
  value?: string,
  attributes?: Record<string, unknown>
  children?: command[]
}

export type RoomListRoom = {name: string, displayName: string, follow?: true, slide?: number/*, display: 'whiteboard'|'screenshare' */}
export type RoomListUser = (UserInfo | ShortUserInfo) & {roomId: string}
export type RoomListConference = {timerExpirationDate: number, funnelChat: boolean, feedbackVisibility: boolean}
export type RoomList = {
  rooms: RoomListRoom[],
  users: RoomListUser[],
  conference: RoomListConference,
  force?: true
}

export const MAIN_ROOM = 'Main Room'

export type Kind = 'audioinput' | 'videoinput' | 'audiooutput'
export type Device = {label: string, kind: Kind, deviceId: string, groupId: string}

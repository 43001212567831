import { getApp } from './models/App';
import { Room } from './models/Room';
import { User } from './models/User';
import router from './router';
import { alert } from './utils';

export function createWebSocket(
  conferenceName: string,
  userId: string,
  jitsiId: string,
  token: string,
  onMessage: (...args: any[]) => any,
) {
  let ws: WebSocket;

  let retries = 0;

  const connect = (
    conferenceName: string,
    userId: string,
    jitsiId: string,
    token: string,
    onMessage: (...args: any[]) => any,
  ) => {
    ws = new WebSocket(`wss://${window.location.host}/ws/_ws/${conferenceName}/${userId}?token=${token}&jitsiId=${jitsiId}`);

    ws.onopen = () => {
      opened = true;
      flush();

      pingPong();
    };

    ws.onerror = (ev) => {
      console.error(ev);
      if (opened) {
        console.log('Websocket errored unexpectedly. Try again in 5 sec');
        opened = false;
        clearTimeout(pingTimeout);
        setTimeout(() => {
          if (++retries > 5) {
            return;
          }
          connect(conferenceName, userId, jitsiId, token, onMessage);
        }, 5000);
      }
    };

    ws.onclose = (ev) => {
      if (opened) {
        console.log('Websocket was closed unexpectedly. Try again in 5 sec');
        opened = false;
        clearTimeout(pingTimeout);
        setTimeout(() => {
          if (++retries > 5) {
            return;
          }
          connect(conferenceName, userId, jitsiId, token, onMessage);
        }, 5000);
      }
    };

    ws.onmessage = ({ data }: { data: string }) => {
      retries = 0;

      // Update kickedCount for the specified user if it's a kick.
      if (data.startsWith('[kick]')) {
        const kickedUserId = data.slice(6);
        const user = User.get(kickedUserId) as User;

        user.kickedCount++;
      }

      if (data.startsWith('[kill]') || data.startsWith('[kick]' + userId) || data.startsWith('[invalidsession]')) {
        clearTimeout(pingTimeout);
        opened = false;
        ws.close();
        try {
          getApp().connection.disconnect();
        } catch (e) {}
        // getApp().connection.currentRoomName = null
        // getApp().running = false

        const app = getApp();

        // If the session is killed or if the user is kicked (or if we somehow have an invalid session), update lastLeftTime to be now.
        User.all()
          .filter((u) => u.visible)
          .forEach((u) => {
            u.lastLeftTime = Date.now();
          });

        // Prepare the attendance report for teachers
        User.all()
          // Get all students (but not recorder)
          .filter((u) => u.role === 'student' && u.name !== 'Recorder')
          // Sort the students alphabetically by name.
          .sort((u1, u2) => {
            return u1.name.localeCompare(u2.name);
          })
          // Add each student's info to app.sessionReport
          .forEach((u) => {
            if (u.absentTimeStamp) {
              u.absentTime += Date.now() - u.absentTimeStamp;
            }
            if (u.awayTimeStamp) {
              u.awayTime += Date.now() - u.awayTimeStamp;
            }

            app.sessionReport.push([
              u.name,
              u.awayTime,
              u.absentTime,
              u.kickedCount,
              u.participationCount,
              u.firstJoinedTime,
              u.lastLeftTime,
            ]);
          });

        if (data.startsWith('[kill]')) {
          // If we're recording, update the recording report because the recording would have stopped.
          if (app.recorderState === 'on') {
            app.recordingReport.push([app.recordingStartedTime, Date.now()]);
          }

          router.replace('/byebye');
        } else if (data.startsWith('[kick]' + userId)) {
          router.replace('/kicked');
        } else {
          router.replace('/invalidsession');
        }

        // Delete all users and rooms
        // User.all().forEach(u => u.delete()) // This is commented out so that the user object is still avaialble on byebye.vue.
        Room.all().forEach((r) => r.delete());

        return;
      }

      if (data.startsWith('[pong]')) {
        clearTimeout(pingTimeout);
        pingPong();
        return;
      }

      onMessage(data);
    };
  };

  let opened = false;
  const queue: string[] = [];

  const send = (msg: string) => {
    if (!opened) {
      queue.push(msg);
    } else {
      ws.send(msg);
    }
  };

  const flush = () => {
    queue.forEach((msg) => {
      send(msg);
    });
  };

  let pingTimeout: ReturnType<typeof setTimeout>;
  let pingRetries = 3;

  const pingPong = () => {
    const pingFailed = () => {
      if (--pingRetries) {
        return sendPing();
      }

      opened = false;
      connect(conferenceName, userId, jitsiId, token, onMessage);
    };

    const sendPing = () => {
      send('[ping]');

      pingTimeout = setTimeout(pingFailed, 5000);
    };

    pingTimeout = setTimeout(sendPing, 10000);
  };

  connect(conferenceName, userId, jitsiId, token, onMessage);

  return {
    close: () => {
      clearTimeout(pingTimeout);
      ws.close();
      opened = false;
    },
    send,
  };
}

<template>
  <div>
    <span v-if="!isTeacher">
      See You Next Time!
    </span>
    <div v-if="isTeacher">
      <table>
        <caption>Session Report</caption>
        <tr>
          <th>Student Name</th>
          <th>Away Time</th>
          <th>Absent Time</th>
          <th>Times Kicked</th>
          <th>Participation</th>
          <th>First Joined</th>
          <th>Last Left</th>
        </tr>
        <tr
          v-for="(report, index) in sessionReport"
          :key="index"
        >
          <td> {{ report[0] }} </td>
          <td
            class="center"
            :class="setColor('time', formatTime(report[1]))"
          >
            {{ formatTime(report[1]) }}
          </td>
          <td
            class="center"
            :class="setColor('time', formatTime(report[2]))"
          >
            {{ formatTime(report[2]) }}
          </td>
          <td
            class="center"
            :class="setColor('kickedCount', report[3])"
          >
            {{ report[3] }}
          </td>
          <td
            class="center"
            :class="setColor('participationCount', report[4])"
          >
            {{ report[4] }}
          </td>
          <td class="center">
            {{ formatTimeStamp(report[5]) }}
          </td>
          <td class="center">
            {{ formatTimeStamp(report[6]) }}
          </td>
        </tr>
      </table>
      <br>
      <table>
        <caption>Recording Report</caption>
        <tr>
          <th>Recording Started</th>
          <th>Recording Stopped</th>
        </tr>
        <tr
          v-for="(report, index) in recordingReport"
          :key="index"
        >
          <td class="center">
            {{ formatTimeStamp(report[0]) }}
          </td>
          <td class="center">
            {{ formatTimeStamp(report[1]) }}
          </td>
        </tr>
      </table>
      <span
        v-if="isTeacher"
        style="margin-top: 75px"
        class="center"
        v-html="rejoinLink"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: bold;
}
table{
  margin-top: 20px;
  border: 2px solid;
  border-collapse: collapse;
  padding: 10px;
  font-size: 26px;
}
th {
  border: 2px solid;
  border-collapse: collapse;
  padding: 10px;
  font-size: 26px;
}
td {
  border: 2px solid;
  border-collapse: collapse;
  padding: 10px;
  font-size: 18px;
}
caption {
  padding: 20px;
  font-size: 40px;
}
.center {
  text-align: center;
}
.red {
  background: red;
  color: white;
  border-color: black;
}
.blue {
  background: blue;
  color: white;
  border-color: black;
}
</style>

<script lang="ts">
import { getApp } from '../models/App'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Welcome',
  setup () {
    const app = getApp()

    // Get the attendance report and recording report data
    const sessionReport = app.sessionReport
    const recordingReport = app.recordingReport

    const formatTime = (timestamp: number) => {
      const date = new Date(timestamp)
      return `${date.getMinutes().toString().padStart(1, '0')}:${date.getSeconds().toString().padStart(2, '0')}`
    }

    const formatTimeStamp = (timeStamp: number) => {
      const date = new Date(timeStamp)
      return date.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit', second: '2-digit' })
    }

    // Create a link that allows teachers to quickly rejoin the sesseion.  (LIMITATION: Their token could be expired.)
    const rejoinURL = `https://${window.location.host}/${app.connection.name}?token=${app.connection.connection.token}`
    const rejoinNote = 'Note: The token you\'re using to join may be expired.  If you have trouble using this link, please join with a new token.'
    const rejoinLink = '<a href="' + rejoinURL + '">Rejoin This Room</a><br><span style="font-size: 50%">' + rejoinNote + '</span>'

    // Determine if the user is (was) a teacher or not.
    const isTeacher = app.isTeacher()

    // For colorizing data in the attendance report
    const setColor = (data: string, value: any) => {
      if (data === 'kickedCount' && value > 0) {
        return 'red'
      }
      if (data === 'participationCount' && value === 0) {
        return 'red'
      }
      if (data === 'time') {
        const [ mins, seconds ] = value.split(':')

        // There is no "counted" time (0:00).
        if (parseInt(mins) === 0 && parseInt(seconds) === 0) {
          return
        }

        // The "counted" time is greater than 1:00.
        if (parseInt(mins) > 1) {
          return 'red'
        }

        // The "counted" time is between 0:00 and 1:00.
        if (parseInt(mins) === 0 && parseInt(seconds) > 0) {
          return 'blue'
        }
      }
    }

    return {
      sessionReport,
      recordingReport,
      formatTime,
      isTeacher,
      rejoinLink,
      formatTimeStamp,
      setColor
    }
  }
})
</script>

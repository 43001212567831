<template>
  <Dialog
    v-slot="{ok}"
    v-model="show"
    title=""
    nofocus
    @ok="onOk"
    @cancel="onCancel"
  >
    <p>{{ msg }}</p>
    <input
      ref="input"
      v-model="text"
      type="text"
      @keydown.enter="ok()"
    >
  </Dialog>
</template>

<script lang="ts">
import Dialog from './Dialog.vue'
import { showPromptDialog, dialogMessage, dialogOk } from '../utils'
import { ref, watch, defineComponent } from 'vue'

export default defineComponent({
  name: 'PromptDialog',
  components: { Dialog },
  setup () {
    const show = showPromptDialog
    const msg = dialogMessage
    const text = ref('')
    const input = ref<HTMLInputElement|null>(null)

    const onOk = () => {
      dialogOk(text.value)
      text.value = ''
    }

    const onCancel = () => {
      text.value = ''
    }

    watch(show, (val) => {
      if (val) {
        input.value?.focus()
      }
    }, { flush: 'post' })

    return {
      onOk,
      onCancel,
      show,
      msg,
      text,
      input
    }
  }
})
</script>

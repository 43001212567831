import { createWebHistory, createRouter } from 'vue-router'
import Welcome from './components/Welcome.vue'
import Loading from './components/Loading.vue'
import ByeBye from './components/ByeBye.vue'
import Kicked from './components/Kicked.vue'
import InvalidSession from './components/InvalidSession.vue'
import UnsupportedBrowser from './components/UnsupportedBrowser.vue'
const history = createWebHistory()
const routes = [
  { path: '/', component: Welcome },
  { path: '/byebye', component: ByeBye },
  { path: '/kicked', component: Kicked },
  { path: '/invalidsession', component: InvalidSession },
  { path: '/unsupported-browser', component: UnsupportedBrowser },
  { path: '/:id', component: Loading, props: true }
]
const router = createRouter({ history, routes })

// const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) || window.webkitConvertPointFromNodeToPage !== undefined

// if (isSafari) {
//   router.replace('/unsupported-browser')
// }

export default router

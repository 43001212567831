<template>
  <div class="root">
    <Dropdown
      noautohide
      :value="app.isHandRaised"
      @click="raiseHand"
    >
      <template #icon>
        <font-awesome-icon icon="hand-paper" />
      </template>
    </Dropdown>

    <Dropdown icon="bxs-smile">
      <div
        :class="{active: isActive('🙂')}"
        @click="setEmoji('🙂')"
      >
        🙂 Happy
      </div>
      <div
        :class="{active: isActive('😕')}"
        @click="setEmoji('😕')"
      >
        😕 Unhappy
      </div>
      <div
        :class="{active: isActive('😵')}"
        @click="setEmoji('😵')"
      >
        😵 Confused
      </div>
      <div
        :class="{active: isActive('👏')}"
        @click="setEmoji('👏')"
      >
        👏 Applause
      </div>
      <div
        :class="{active: isActive('🤯')}"
        @click="setEmoji('🤯')"
      >
        🤯 Mind Blown
      </div>
      <div
        :class="{active: isActive('💡')}"
        @click="setEmoji('💡')"
      >
        💡 I get it!!
      </div>
      <div
        :class="{active: isActive('🏳️')}"
        @click="setEmoji('🏳️')"
      >
        🏳️ I give up.
      </div>
      <div
        :class="{active: isActive('🥳')}"
        @click="setEmoji('🥳')"
      >
        🥳 Celebration!
      </div>
    </Dropdown>

    <Dropdown icon="bxs-user">
      <div
        :class="{active: isActiveUserStatus('⏳')}"
        @click="setUserStatus('⏳')"
      >
        ⏳ Away / AFK
      </div>
      <div
        :class="{active: isActiveUserStatus('🤔')}"
        @click="setUserStatus('🤔')"
      >
        🤔 Thinking
      </div>
      <div
        :class="{active: isActiveUserStatus('📝')}"
        @click="setUserStatus('📝')"
      >
        📝 Taking Notes
      </div>
      <div
        :class="{active: isActiveUserStatus('🤣')}"
        @click="setUserStatus('🤣')"
      >
        🤣 LOL!!
      </div>
      <div
        :class="{active: isActiveUserStatus('🔇')}"
        @click="setUserStatus('🔇')"
      >
        🔇 Can't Hear / Tech. Issue
      </div>
    </Dropdown>

    <!-- <Dropdown icon="bx-line-chart-down">
  </Dropdown> -->

    <Dropdown
      icon="bxs-bar-chart-alt-2"
      :color="!app.feedbackVisibility ? 'red' : ''"
    >
      <div
        :class="{active: isActiveFeedback('✔️')}"
        @click="setFeedback('✔️')"
      >
        ✔️ Yes
      </div>
      <div
        :class="{active: isActiveFeedback('😵')}"
        @click="setFeedback('😵')"
      >
        😵 Unsure
      </div>
      <div
        :class="{active: isActiveFeedback('❌')}"
        @click="setFeedback('❌')"
      >
        ❌ No
      </div>

      <div
        :class="{active: !isNaN(Number(app.feedback))}"
      >  
        <span 
          @click="setFeedback('')"
          style="top: -2px; position: relative;"
        >⭐</span>
        <span style="font-size: 20px; padding: 2px">{{ feedbackRating }}</span>

        <input 
          v-model="feedbackRating"
          type="range" min="0" max="10"
          @change="setFeedback(($event.target as HTMLInputElement).value)"
        >
      </div>

      <div class="divider" />

      <div
        :class="{active: isActiveFeedback('👍')}"
        @click="setFeedback('👍')"
      >
        👍 Thumbs up
      </div>
      <div
        :class="{active: isActiveFeedback('👎')}"
        @click="setFeedback('👎')"
      >
        👎 Thumbs down
      </div>

      <div
        :class="{active: isActiveFeedback('<i class=\'bx bxs-right-arrow\'/>')}"
        @click="setFeedback('<i class=\'bx bxs-right-arrow right-arrow\' />')"
      >
        <i class="bx bxs-right-arrow right-arrow" /> Faster
      </div>
      <div
        :class="{active: isActiveFeedback('<i class=\'bx bxs-left-arrow\'/>')}"
        @click="setFeedback('<i class=\'bx bxs-left-arrow left-arrow\' />')"
      >
        <i class="bx bxs-left-arrow left-arrow" /> Slower
      </div>

      <div class="divider" />

      <div
        v-if="app.isTeacher()"
        @click="setFeedbackVisibility()"
      >
        <span
          v-if="app.feedbackVisibility"
          style="color: red"
        >Hide Feedback <i class="bx bxs-low-vision" /></span>
        <span
          v-else
          style="color: green"
        >Show Feedback <i class="bx bxs-show" /></span>
      </div>
    </Dropdown>
    <Dropdown
      v-if="app.isTeacher()"
      icon="bxs-music"
    >
      <div
        class="row soundEffect"
        style="background: none; outline: none"
      >
        <div
          class="item"
          @click="setSoundEffect('alert')"
        >
          Alert!
        </div>
        <div
          class="item"
          @click="setSoundEffect('downer')"
        >
          Downer
        </div>
        <div
          class="item"
          @click="setSoundEffect('losing')"
        >
          Losing
        </div>
        <div
          class="item"
          @click="setSoundEffect('intense')"
        >
          Intense!
        </div>
        <div
          class="item"
          @click="setSoundEffect('ding')"
        >
          Ding
        </div>
      </div>
      <div
        class="row soundEffect"
        style="background: none; outline: none"
      >
        <div
          class="item"
          @click="setSoundEffect('bell')"
        >
          Bell
        </div>
        <div
          class="item"
          @click="setSoundEffect('cheeringCrowd')"
        >
          Cheering Crowd
        </div>
        <div
          class="item"
          @click="setSoundEffect('crickets')"
        >
          Crickets
        </div>
        <div
          class="item"
          @click="setSoundEffect('shovelBonk')"
        >
          Shovel Bonk
        </div>
        <div
          class="item"
          @click="setSoundEffect('fbiOpenUp')"
        >
          FBI Open Up!
        </div>
      </div>
      <div
        class="row soundEffect"
        style="background: none; outline: none"
      >
        <div
          class="item"
          @click="setSoundEffect('success')"
        >
          Success!
        </div>
        <div
          class="item"
          @click="setSoundEffect('upper')"
        >
          Upper
        </div>
        <div
          class="item"
          @click="setSoundEffect('sadViolin')"
        >
          Sad Voilin
        </div>
        <div
          class="item"
          @click="setSoundEffect('angelicChoir')"
        >
          Angelic Choir
        </div>
      </div>
      <div
        class="row soundEffect"
        style="background: none; outline: none"
      >
        <div
          class="item"
          @click="setSoundEffect('magic')"
        >
          Magic
        </div>
        <div
          class="item"
          @click="setSoundEffect('ohhCrowd')"
        >
          Ohhhh!!!
        </div>
        <div
          class="item"
          @click="setSoundEffect('policeSiren')"
        >
          Police Siren
        </div>
        <div
          class="item"
          @click="setSoundEffect('powerUp')"
        >
          Power Up
        </div>
        <div
          class="item"
          @click="setSoundEffect('doink')"
        >
          Doink
        </div>
      </div>
      <div
        class="row soundEffect"
        style="background: none; outline: none"
      >
        <div
          class="item"
          @click="setSoundEffect('slap')"
        >
          Slap
        </div>
        <div
          class="item"
          @click="setSoundEffect('sneaky')"
        >
          Sneaky
        </div>
        <div
          class="item"
          @click="setSoundEffect('yes')"
        >
          Yes!!
        </div>
        <div
          class="item"
          @click="setSoundEffect('yuck')"
        >
          Yuck
        </div>
        <div
          class="item"
          @click="setSoundEffect('badumTiss')"
        >
          Ba Dum Tiss
        </div>
      </div>
      <div
        class="row soundEffect"
        style="background: none; outline: none"
      >
        <div
          class="item"
          @click="setSoundEffect('gasp')"
        >
          Gasp!
        </div>
        <div
          class="item"
          @click="setSoundEffect('bruh')"
        >
          Bruh
        </div>
        <div
          class="item"
          @click="setSoundEffect('clapping')"
        >
          Clapping
        </div>
        <div
          class="item"
          @click="setSoundEffect('crash')"
        >
          Crash
        </div>
        <div
          class="item"
          @click="setSoundEffect('blueDanubeWaltz')"
        >
          Blue Danube Waltz
        </div>
      </div>
      <div
        class="row soundEffect"
        style="background: none; outline: none"
      >
        <div
          class="item"
          @click="setSoundEffect('dramaticEffect')"
        >
          Dramatic Effect
        </div>
        <div
          class="item"
          @click="setSoundEffect('drumRollTada')"
        >
          Drum Roll &rarr; Tada
        </div>
        <div
          class="item"
          @click="setSoundEffect('dunDunDUN')"
        >
          Dun, DUN, DUN!!
        </div>
        <div
          class="item"
          @click="setSoundEffect('evilLaugh')"
        >
          Evil Laugh
        </div>
        <div
          class="item"
          @click="setSoundEffect('pewPew')"
        >
          Pew Pew
        </div>
      </div>
      <div
        class="row soundEffect"
        style="background: none; outline: none"
      >
        <div
          class="item"
          @click="setSoundEffect('fallingBomb')"
        >
          Falling Bomb
        </div>
        <div
          class="item"
          @click="setSoundEffect('laughingCrowd')"
        >
          Laughing 1
        </div>
        <div
          class="item"
          @click="setSoundEffect('laughingCrowd2')"
        >
          Laughing 2
        </div>
        <div
          class="item"
          @click="setSoundEffect('pacManDying')"
        >
          Pac Man Dying
        </div>
        <div
          class="item"
          @click="setSoundEffect('wompwomp')"
        >
          Womp Womp
        </div>
        <div
          class="item"
          @click="setSoundEffect('wrongBuzzer')"
        >
          Wrong Buzzer
        </div>
      </div>
      <div
        class="row"
        style="background: none; outline: none"
      >
        <div
          class="stopSoundEffect item"
          @click="setSoundEffect('')"
        >
          Stop
        </div>
      </div>
      <div
        class="row"
        style="background: none; outline: none"
      >
        <div
          class="fireWorksError item"
          @click="fireWorksCelebration('A mistake we can all learn from!!')"
        >
          Fireworks Celebration for Mistake
        </div>
      </div>

      <!-- <span v-html=column1Sounds />
        <div v-html=column2Sounds />
        <div v-html=column3Sounds /> -->
    </Dropdown>

    <!--

      This space can be used in the future for a moderator "side menu" (like what currently exists for breakout rooms) to handle things like publishing responses, changing poll types, etc.

      <div
      v-if="app.isTeacher()"
      style="color: white;"
    >
      {{ up }}/{{ down }}
    </div> -->
  </div>
</template>

<style>
.right-arrow{
  color: green;
}
.left-arrow{
  color: red;
}
</style>

<style lang="scss" scoped>
@import '../main.scss';

div.root {
  height: 32px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0 5px;
}

div.root > div {
  position: relative;
  font-size: 20px;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 28px;
  cursor: pointer;
}

.row {
  padding: 5px
}

.item {
  padding: 10px;
  outline: 1px solid black;
  cursor: pointer;
  width: -webkit-fill-available;
  text-align: center;

  &:hover {
    background: $room-highlight;
  }
}
.soundEffect {
  font-size: 16px;
  padding: 5px
}

.stopSoundEffect {
  width: 100%;
  color: red;
  background: white;
}

.fireWorksError {
  width: 100%;
  color: blue;
  background: white;
}

.divider {
  border-top: 1px solid;
  height: 0px;
  padding: 0px !important;
  margin: 5px;
  cursor: default;
}


/*Safari and Chrome*/
input[type="range"] {
    -webkit-appearance: none;
    appearance: none; 
    height: 1rem;
    cursor: pointer;
    overflow: hidden;
    border-radius: 1rem;
    border: 2px solid black;
    background: black;
}

input[type="range"]::-webkit-slider-runnable-track {
    background: black;
    height: 1rem;
    border-radius: 1rem;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none; 
    height: 1rem;
    width: 1rem;
    background-color: white;
    border-radius: 50%;
  /*slider-thumb border color*/
    border: 1px solid hsl(120, 100%, 65%);

  /*Colors*/
    box-shadow: -50rem 0 5rem 20rem hsl(100, 48%, 78%),
                -40rem 0 5rem 30rem hsl(110, 45%, 62%),
                -30rem 0 2rem 25rem hsl(115, 47%, 38%),
                -25.5rem 0 0 25rem  hsl(120, 81%, 41%);
}
</style>

<script lang="ts">
import { getApp } from '../models/App'
import { watch, computed, defineComponent, onMounted, ref } from 'vue'
import Dropdown from './Dropdown.vue'
import { User } from '../models/User'
import { event } from 'jquery';

export default defineComponent({
  name: 'StatusIndicator',
  components: {
    Dropdown
  },
  setup () {
    const app = getApp()

    const setEmoji = (emoji: string) => {
      app.emoji = emoji === app.emoji ? '' : emoji
    }

    const isActive = (emoji: string) => {
      return app.emoji === emoji
    }

    const setFeedback = (feedback: string | number) => {
      // Reset the slider to 0 if the feedback is blank (a reset call) or if it's not a number (green check, thumbs up, etc.)
      if(feedback == '' || !Number.isInteger(parseInt(feedback))){
        feedbackRating.value = 0
      }
      app.feedback = feedback === app.feedback ? '' : feedback
    }

    const feedbackRating = ref(0)

    const isActiveFeedback = (feedback: string) => {
        return app.feedback === feedback
    }

    const setUserStatus = (userStatus: string) => {
      app.userStatus = userStatus === app.userStatus ? '' : userStatus
    }

    const isActiveUserStatus = (userStatus: string) => {
      return app.userStatus === userStatus
    }

    const raiseHand = () => {
      app.isHandRaised = !app.isHandRaised
    }

    const setFeedbackVisibility = () => {
      app.feedbackVisibility = !app.feedbackVisibility
      app.restrictedSend(`[feedbackVisibility]${app.feedbackVisibility}`)
    }

    watch(() => app.isHandRaised, (newValue) => {
      app.raiseHand(app.connection.id, newValue)
    })

    watch(() => app.emoji, (value: string, oldValue: string) => {
      app.setUserEmoji(app.connection.id, value)
    })

    watch(() => app.feedback, (value: string, oldValue: string) => {
      app.setUserFeedback(app.connection.id, value)
    })

    watch(() => app.userStatus, (value: string, oldValue: string) => {
      app.setUserStatus(app.connection.id, value)
    })

    // const up = computed(() => User.all().filter(u => u.feedback === '✔️' && u.visible).length)
    // const down = computed(() => User.all().filter(u => u.feedback === '❌' && u.visible).length)

    // For playing/stopping a specified sound effect
    const setSoundEffect = (soundName: string) => {
      // Send info. to the server to play or stop the sound effect.
      app.send(`[soundEffect]${soundName}`)
    }

    // For displaying a firework celebration when a mistake occurs
    const fireWorksCelebration = (text: string) => {
      // Send info. to the server so everyone gets the effect.
      app.send(`[fireWorksCelebration]${text}`)
    }

    // For catching keyboard shortcuts
    onMounted(() => {
      document.addEventListener('keydown', globalKeyDown)
    })

    // Making keyboard shortcuts work
    const globalKeyDown = (ev: KeyboardEvent) => {
      if (ev.ctrlKey) {
        // Using ev.preventDefault() below to prevent the browser from using default keyboard shortcuts that would interfere or be done concurrently with what we want below.
        // It would be better to use it here, but that prevents text from being copy/pasted in the the chat.

        if (ev.key === '1') {
          ev.preventDefault()
          setFeedback('✔️')
        }
        if (ev.key === '2') {
          ev.preventDefault()
          setFeedback('❌')
        }
        if (ev.key === '3') {
          ev.preventDefault()
          setFeedback('😵')
        }
        if (ev.key === '4') {
          ev.preventDefault()
          raiseHand()
        }
      }
    }

    return {
      app,
      setEmoji,
      isActive,
      setFeedback,
      isActiveFeedback,
      setUserStatus,
      isActiveUserStatus,
      raiseHand,
      setSoundEffect,
      setFeedbackVisibility,
      fireWorksCelebration,
      feedbackRating
    }

  }
})
</script>

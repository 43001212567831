import { Model } from 'vue-model';
import { whiteBoardAPI } from '../components/whiteboardAPI';
import { ChatMessage, UserInfo, Permissions, RoomList, MAIN_ROOM, defaultPermissions, Device } from '../types';
import { createWebSocket } from '../websocket';
import { Connection } from './Connection';
import { Room } from './Room';
import { User } from './User';
import { alert } from '../utils';
import { MAX_ROOM_NAME_LENGTH } from '../config';
import { Fireworks } from 'fireworks-js';

export class App extends Model {
  static model = 'App';

  connection!: Connection;
  currentSoundEffect!: string;
  sounds!: { [sound: string]: HTMLAudioElement };
  soundEffects!: { [sound: string]: HTMLAudioElement };

  ws: { send: (...args: any[]) => any; close: (...args: any[]) => any } | null = null;
  disableAudio!: boolean;
  disableVideo!: boolean;
  disableChat!: boolean;
  timerExpirationDate!: number;
  whiteBoardEnabled!: boolean;
  isScreenShareEnabled!: boolean;
  isAudioEnabled!: boolean;
  isVideoEnabled!: boolean;
  isHandRaised!: boolean;
  chatIndicator!: { [chat: string]: boolean };
  talkWhileMuted!: boolean;
  noisyMic!: boolean;
  noAudio!: boolean;
  running!: boolean;
  permissions!: Permissions;
  showRoomMenu!: boolean;
  showSessionMenu!: boolean;
  emoji!: string;
  feedback!: string | number;
  feedbackVisibility!: boolean;
  userStatus!: string;
  currentChat!: string;
  currentPrivateChat!: string | null;
  devices!: Device[];
  cameraDeviceId!: string;
  micDeviceId!: string;
  outputDeviceId!: string;
  volume!: number;
  gainNode!: GainNode;
  audioCtx!: AudioContext;
  inputGain!: number;
  recorderState!: 'on' | 'off' | 'pending';
  recordingSession!: any;
  fullscreen!: boolean;
  showVideo!: boolean;
  audioNotPermitted!: boolean;
  videoNotPermitted!: boolean;
  dockRight!: boolean;
  status!: string;
  isRecorder!: boolean;
  eventLog!: [msg: string, time: number][];
  showInvisibleUsers!: boolean;
  funnelChat!: boolean;
  sessionReport!: [
    userName: string,
    awayTime: number,
    absentTime: number,
    kickedCount: number,
    participationCount: number,
    joinedTime: number,
    leftTime: number,
  ][];
  recordingReport!: [startedTime: number, stoppedTime: number][];
  recordingStartedTime!: number;

  static fields() {
    return {
      disableAudio: this.boolean(false),
      disableVideo: this.boolean(false),
      timerExpirationDate: this.number(0),
      whiteBoardEnabled: this.boolean(true),
      isScreenShareEnabled: this.boolean(false),
      isAudioEnabled: this.boolean(false),
      isVideoEnabled: this.boolean(false),
      isHandRaised: this.boolean(false),
      chatIndicator: this.field<{ [chat: string]: boolean }>({}, Object as any),
      talkWhileMuted: this.boolean(false),
      noisyMic: this.boolean(false),
      noAudio: this.boolean(false),
      running: this.boolean(true),
      permissions: this.field<Permissions>(defaultPermissions, Object as any),
      showRoomMenu: this.boolean(false),
      showSessionMenu: this.boolean(false),
      emoji: this.string(''),
      feedback: this.string(''),
      feedbackVisibility: this.boolean(true),
      userStatus: this.string(''),
      currentChat: this.string(MAIN_ROOM),
      currentPrivateChat: this.string(null),
      devices: this.field<Device[]>([], Object as any),
      cameraDeviceId: this.string('default'),
      micDeviceId: this.string('default'),
      outputDeviceId: this.string('default'),
      volume: this.number(1),
      inputGain: this.number(1),
      recorderState: this.string('off'),
      fullscreen: this.boolean(false),
      showVideo: this.boolean(false),
      audioNotPermitted: this.boolean(false),
      videoNotPermitted: this.boolean(false),
      dockRight: this.boolean(false),
      status: this.string(),
      isRecorder: this.boolean(false),
      eventLog: this.field<[msg: string, time: number][]>([], Object as any),
      showInvisibleUsers: this.boolean(false),
      funnelChat: this.boolean(false),
      sessionReport: this.field<
        [userName: string, awayTime: number, absentTime: number, kickedCount: number, participationCount: number][]
      >([], Object as any),
      recordingReport: this.field<[startedTime: number, stoppedTime: number][]>([], Object as any),
      recordingStartedTime: this.number(0),
    };
  }

  newRoom(name: string) {
    const room = (Room as any).get(name) as Room;

    if (room) return name;

    this.ws?.send('[room]create' + name);
    return name;
  }

  createRoom(name: string, displayName: string) {
    const room = (Room as any).get(name) as Room;

    if (room) return room;

    return Room.create({ name, displayName, connectionId: this.connection.id });
  }

  initWs(isRecorder: boolean, token: string, jitsiId: string) {
    this.ws = createWebSocket(this.connection.name, this.connection.id, jitsiId, token, (msg: string) => this.onWSMessage(msg));
  }

  async onWSMessage(msg: string) {
    console.log('[~ws]: ' + msg);
    if (msg.startsWith('[room]')) {
      console.log('[room] received list');

      const info = JSON.parse(msg.slice(6)) as RoomList;
      const roomExists = (name: string) => info.rooms.some((r) => r.name === name);

      // Update conference info.
      this.timerExpirationDate = info.conference.timerExpirationDate;
      this.funnelChat = info.conference.funnelChat;
      this.feedbackVisibility = info.conference.feedbackVisibility;

      // Make sure all rooms exist in the model registry
      info.rooms.forEach((r) => {
        this.createRoom(r.name, r.displayName);
        console.log(r);
      });

      // Make sure all users are up to date
      for (const user of info.users) {
        const oldUser = User.get(user.id);
        const oldVisibility = oldUser?.visible;

        // Update the user
        const newUser = User.fillOrCreate({ ...user });

        // Reset status indicators
        if (newUser.id === this.connection.id) {
          this.userStatus = newUser.userStatus;
          this.isHandRaised = newUser.hand;
          this.emoji = newUser.emoji;
          this.feedback = newUser.feedback;

          // Make sure to update with any changed permissions that are different from the default ones.
          this.onPermissionsChanged();
        }

        // If this user didnt exist before, its a join
        if (!oldUser) {
          // Update the event log about the fact that someone joined the session OR that recording was started (in the case of "Recorder").
          if (newUser.name !== 'Recorder') {
            this.logEvent('Recording was started.');

            // Update app.recordingStartedTime because the recording was started.
            // this.recordingStartedTime = Date.now()
          } else {
            this.logEvent(`${newUser.name} joined the session.`);
          }

          whiteBoardAPI.addUsers([newUser]);
        } else {
          // If the user DID exist before but its visibility turned to false,
          // its a leave
          if (oldVisibility && !newUser.visible && !this.isRecorder) {
            if (newUser.name !== 'Recorder') {
              if (JSON.parse(localStorage.getItem('leftSessionDing'))) {
                this.sounds.leftSession.play();
              } // Only play sounds if the preference has been set to allow it
            }

            // Set lastLeftTime for this user
            newUser.lastLeftTime = Date.now();

            // Set absentTimeStamp for this user
            newUser.absentTimeStamp = Date.now();

            // Update the event log about the fact that someone left the session.
            this.logEvent(`${newUser.name} left the session.`);
          }

          if (!oldVisibility && newUser.visible) {
            newUser.absentTime += Date.now() - newUser.absentTimeStamp;
            newUser.absentTimeStamp = 0;
          }
        }
      }

      const room = User.get(this.connection.id)?.roomId as string;
      const roomToJoin = roomExists(room) ? room : MAIN_ROOM;

      // If a switch is required we do it before we change slides
      await this.connection.switch(roomToJoin as string).then(() => {
        // This is needed in order to make jibri work
        // It is expected to be defined
        window.APP.conference._room = this.connection.currentRoom?.conference;
      });

      for (const room of info.rooms) {
        if (room.follow) {
          this.onFollow([room.name, room.follow].join(':'));
          this.onCurrentSlide([room.name, room.slide].join(':'));
        }
      }

      // Clean up deleted rooms
      // We do this at the very end because we need the object to leave the jitsi conference
      Room.all()
        .filter((r) => !roomExists(r.name))
        .forEach((r) => r.delete());
    } else if (msg.startsWith('[permission]')) {
      console.log('[room] permissions received');

      const [userId, permission, val] = msg.slice(12).split(':') as [string, keyof Permissions, 'true' | 'false'];

      const value = JSON.parse(val);

      if (userId === 'global') {
        User.all().forEach((u) => {
          u.permissions[permission] = value;
        });
        this.permissions[permission] = value;
      } else {
        const user = User.get(userId);
        if (!user) return;
        user.permissions[permission] = value;
      }

      if (userId === 'global' || userId === this.connection.id) {
        this.onPermissionsChanged();
      }
    } else if (msg.startsWith('[permissions]')) {
      const permissions = JSON.parse(msg.slice(13));

      User.all().forEach((u) => {
        Object.assign(u.permissions, permissions);
      });

      Object.assign(this.permissions, permissions);

      this.onPermissionsChanged();
    } else if (msg.startsWith('[user]')) {
      console.log('[user] info received');

      const {
        name,
        role,
        id,
        emoji,
        feedback,
        userStatus,
        hand,
        visible,
        permissions,
        jitsiId,
        roomId,
        isOnAudio,
        isOnCamera,
        isOnScreenShare,
        firstJoinedTime,
      } = JSON.parse(msg.slice(6)) as UserInfo;
      // const permissions = this.permissions

      const user = User.fillOrCreate({
        name,
        role,
        emoji,
        feedback,
        userStatus,
        hand,
        id,
        visible,
        permissions,
        jitsiId,
        roomId,
        isOnAudio,
        isOnCamera,
        isOnScreenShare,
      });

      // Update user.firstJoinedTime.  This value is defined on the server.
      user.firstJoinedTime = firstJoinedTime;

      // Update user.absentTime and user.absentTimeStamp.
      if (user.absentTimeStamp) {
        user.absentTime += Date.now() - user.absentTimeStamp;
        user.absentTimeStamp = 0;
      }

      whiteBoardAPI.addUsers([user]);

      // Update the event log about the fact that someone joined the session OR that recording was started (in the case of "Recorder").
      // Also potentially give an auditory indication of this joining
      if (user.name === 'Recorder') {
        //Inform everyone that the recording was started
        //this.restrictedSend(`[recording]${1}`);

        //this.sounds.recordingStarted.play(); // This is limited because the sound plays while the button still says Pending due to the creation of the Chrome instance for recording.

        //this.logEvent('Recording was started.');
      } else {
        // If the user has previously joined, and is refreshing, we don't play the ding as it can be too much with lots of refreshing.
        // Currently the "how long has it been since the user left" value is set to 10 seconds by (Date.now() - lastLeftTime) > 10000.
        if (Date.now() - user.lastLeftTime > 10000) {
          if (JSON.parse(localStorage.getItem('joinedSessionDing'))) {
            this.sounds.joinedSession.play();
          }
        }

        this.logEvent(`${name} joined the session.`);
      }

      user.justJoined = true;

      setTimeout(() => {
        user.justJoined = false;
      }, 5100);
    } else if (msg.startsWith('[emoji]')) {
      const [id, emoji] = msg.slice(7).split(':');
      console.log('[emoji] received', id, emoji);
      const user = User.get(id);

      if (!user) return;
      user.emoji = emoji;
    } else if (msg.startsWith('[feedback]')) {
      const [id, feedback] = msg.slice(10).split(':');
      console.log('[feedback] received', id, feedback);
      const user = User.get(id);

      if (!user) return;
      user.feedback = feedback;
    } else if (msg.startsWith('[userStatus]')) {
      const [id, userStatus] = msg.slice(12).split(':');
      console.log('[userStatus] received', id, userStatus);
      const user = User.get(id);

      if (!user) return;

      if (userStatus === '⏳' && user.userStatus !== '⏳') {
        // Marking themselves away.
        user.awayTimeStamp = Date.now();
      }
      if (user.userStatus === '⏳' && userStatus === '') {
        // Marking themselves as being back from being away.
        user.awayTime += Date.now() - user.awayTimeStamp;
        user.awayTimeStamp = 0;
      }

      user.userStatus = userStatus;
    } else if (msg.startsWith('[timer]')) {
      const timestamp = parseInt(msg.slice(7));
      this.timerExpirationDate = timestamp;
    } else if (msg.startsWith('[hand]')) {
      const [id, val] = msg.slice(6).split(':');
      const yes = val === 'true';

      const user = User.get(id);

      if (!user) return;
      user.hand = yes;
      user.handTime = Date.now();

      // Play a sound if the someone's hand is raised or lowered.
      if (yes) {
        // Hand raised
        if (id === this.connection.id) {
          if (JSON.parse(localStorage.getItem('myRaiseHandDing'))) {
            this.sounds.myRaiseHand.play();
          }
        } else {
          if (JSON.parse(localStorage.getItem('otherRaiseHandDing'))) {
            this.sounds.otherHandRaised.play();
          }
          // if (this.isTeacher()) this.sounds.otherHandRaised.play()  // Should it be just the teachers that hear this one?
        }
      } else {
        // Hand lowered
        if (JSON.parse(localStorage.getItem('myLowerHandDing'))) {
          this.sounds.myLowerHand.play();
        }
      }
    } else if (msg.startsWith('[chat]')) {
      const chat = JSON.parse(msg.slice(6)) as ChatMessage[];
      chat.forEach((msg) => {
        if (msg.to) {
          // This is a private chat
          if (msg.to === this.connection.id) {
            // For the receiver of the private chat
            User.get(msg.from)?.messages.push(msg);
            this.chatIndicator[msg.from] = true;
            if (JSON.parse(localStorage.getItem('privateChatReceivedDing'))) {
              this.sounds.privateChatReceived.play();
            }
          } else if (msg.from === this.connection.id) {
            // For the sender of the private chat
            User.get(msg.to)?.messages.push(msg);
            this.chatIndicator[msg.to] = true;
          } else if (this.isTeacher()) {
            // Teachers see all private chats
            User.get(msg.from)?.messages.push(msg);
          }
        } else {
          // Allow the display of the chat if we have a valid connection &&...
          // 1.) We are not funneling the chat ||...
          // 2.) The chat is from the user...
          // 3.) We ARE funneling the chat, but the user is a teacher.
          if (this.connection && (!this.funnelChat || msg.from === this.connection.id || (this.funnelChat && this.isTeacher()))) {
            
            if(this.funnelChat && this.isRecorder) return; //"Hack" to ensure recorder doesn't see funneled chats

            this.chatIndicator[this.connection.currentRoomName as string] = true;
            this.connection.currentRoom?.messages?.push(msg);
          }
        }
      });

      // if (lastInc)
    } else if (msg.startsWith('[funnelChat]')) {
      // Set app.funnelChat as true/false
      this.funnelChat = msg.slice(12) === 'true';
    } else if (msg.startsWith('[switch]')) {
      const [id, roomId] = msg.slice(8).split(':');
      if (this.connection.id === id) {
        this.connection.switch(roomId).then(() => {
          // This is needed in order to make jibri work
          // It is expected to be defined
          window.APP.conference._room = this.connection.currentRoom?.conference;
        });
      }
    } else if (msg.startsWith('[follow]')) {
      this.onFollow(msg.slice(8));
    } else if (msg.startsWith('[renameRoom]')) {
      msg = msg.slice(12);
      const [roomName, newName] = msg.split(':');

      const room = (Room as any).get(roomName) as Room;

      // Rename the room and limit the length to MAX_ROOM_NAME_LENGTH
      room.displayName = newName.slice(0, MAX_ROOM_NAME_LENGTH);
    } else if (msg.startsWith('[slide]')) {
      this.onCurrentSlide(msg.slice(7));
    } else if (msg.startsWith('[announcement]')) {
      if (!this.isRecorder) {
        alert(msg.slice(14));
        // Play a sound when the announcement is posted.
        this.sounds.announcementPosted.play()
      }
    } else if (msg.startsWith('[recording]')) {
      const started = msg.slice(11);
      if (!this.isRecorder) {
        // Give an auditory indication that the recording has started or stopped.
        (started === '1') ? this.sounds.recordingStarted.play() : this.sounds.recordingStopped.play();
      }
    } else if (msg.startsWith('[typing]')) {
      msg = msg.slice(8);
      const [yes, userId, chat] = msg.split(':');
      const user = User.get(userId);

      if (!user) return;

      if (Room.get(chat)?.name === this.connection.currentRoomName || chat === this.connection.id || this.isTeacher()) {
        user.isTyping = yes === '1';
      }
    } else if (msg.startsWith('[drawing]')) {
      msg = msg.slice(9);
      const [yes, userId] = msg.split(':');
      const user = User.get(userId);

      if (!user) return;
      user.isDrawing = yes === '1';
    } else if (msg.startsWith('[reset]')) {
      User.all().forEach((u) => {
        u.feedback = '';
      });

      this.feedback = '';
    } else if (msg.startsWith('[eventLog]')) {
      // Update eventLog with event
      const txt = msg.slice(10);
      this.eventLog.push([txt, Date.now()]);
    } else if (msg.startsWith('[eventLogs]')) {
      this.eventLog = JSON.parse(msg.slice(11));
    } else if (msg.startsWith('[soundEffect]')) {
      const soundName = msg.slice(13);

      // If soundName is an empty string, that is the command to stop playing the current sound
      if (soundName === '') {
        this.soundEffects[this.currentSoundEffect]?.load();

        // Set currentSoundEffect to '' to keep things "clean"
        this.currentSoundEffect = '';
      } else {
        // Set currentSoundEffect so that it can be turned off by stopSoundEffect() if needed.
        this.currentSoundEffect = soundName;

        // Set the volume lower because the default is too loud for some people.
        this.soundEffects[soundName].volume = 0.5;

        // Play the sound effect
        this.soundEffects[soundName].play();
      }
    } else if (msg.startsWith('[feedbackVisibility]')) {
      const trueOrFalse = msg.slice(20);
      this.feedbackVisibility = trueOrFalse === 'true';
    } else if (msg.startsWith('[track]')) {
      const submsg = msg.slice(7);
      const type = Number(submsg.slice(0, 1));
      const [userId, yesOrNo] = submsg.slice(1).split(':');

      const track = ['isOnAudio', 'isOnCamera', 'isOnScreenShare'][type] as 'isOnAudio' | 'isOnCamera' | 'isOnScreenShare';

      const user = User.get(userId);

      if (!user) return;

      user[track] = yesOrNo === 'true';
    } else if (msg.startsWith('[whiteboardReset]')) {
      // A moderator requested to reset your whiteboard
      whiteBoardAPI.reset(this.connection.currentRoom!.serverName);
    } else if (msg.startsWith('[confetti]')) {
      // Show confetti and play sound effect.  CanCan song is 10 seconds, but do confetting for 8 so that it leaves the screen as the song ends.

      // @ts-expect-error sadlfkj
      startConfetti(); // eslint-disable-line no-undef

      this.sounds.CanCan.play();

      const userId = msg.slice(10);
      const user = User.get(userId);

      // Set user.confetti to true in order to flash the user name who is being "confettied"
      user!.confetti = true;

      // Set user.confetti to false to turn off the flashing
      setTimeout(() => {
        user!.confetti = false;
      }, 10000);

      // @ts-expect-error sadlfkj
      setTimeout(stopConfetti, 8000); // eslint-disable-line no-undef
    } else if (msg.startsWith('[fireWorksCelebration]')) {
      // Extract the text
      const text = msg.slice(22);

      // Start fireworks
      const container = document.querySelector('.displayarea')!;
      const fireworks = new Fireworks(document.body, {
        opacity: 0.5,
        particles: 100,
        intensity: 30,
      });
      fireworks.start();

      // Add styling to get them to display properly
      fireworks.canvas.setAttribute(
        'style',
        'position: absolute; top: 0px; left: 0px; pointer-events: none; width: 100%; height: 100%',
      );

      // Play the music that goes with the fireworks and append the text.  Note: The fireworks need about a half sec. head start.
      setTimeout(() => {
        this.sounds.fireworksMusic.play();
        container.insertAdjacentHTML('beforeend', '<div class="fireWorksText">' + text + '</div>');
      }, 500);

      // Stop fireworks and remove canvas after 13 seconds (matches the music length)
      setTimeout(() => {
        fireworks.stop();
        fireworks.canvas.remove();
        const fireworksTexts = document.querySelectorAll('.fireWorksText');

        fireworksTexts.forEach((fireWorksText) => {
          fireWorksText.remove();
        });
      }, 13000);
    } else if (msg.startsWith('[updateParticipation]')) {
      const [participationCount, userId] = msg.slice(21).split(':') as [string, string];
      const user = User.get(userId);

      if (!user) return;

      // Set user.participationCount
      user.participationCount = parseInt(participationCount);
    } else if (msg.startsWith('[cloak]')) {
      const [userId, cloak] = msg.slice(7).split(':') as [string, string];
      const user = User.get(userId);

      if (!user) return;

      user.cloaked = cloak === 'true';
    }
  }

  onFollow(msg: string) {
    console.log('follow changed to', msg);

    const [room, val] = msg.split(':');

    const value = val === 'true';

    const r = this.connection.rooms.find((r) => r.$id === room);

    if (!r || value === r.follow) return;

    r.follow = value;

    if (r.follow) {
      if (r.name === this.connection.currentRoomName && r.teachersCurrentSlide !== r.currentSlide) {
        r.slideChangeInProgress++;
        whiteBoardAPI.navigate(r.serverName, r.teachersCurrentSlide);
      }
    }
  }

  onCurrentSlide(msg: string) {
    console.log('current slide changed to', msg);

    const [room, val, forced] = msg.split(':');

    const slide = parseInt(val);

    const r = this.connection.rooms.find((r) => r.$id === room);

    if (!r) return;

    // Always update teachers slide to be sure that we keep track of it
    r.teachersCurrentSlide = slide;

    if (slide === r.currentSlide) return;

    if (r.name === this.connection.currentRoomName && (r.follow || forced === 'true')) {
      r.slideChangeInProgress++;
      whiteBoardAPI.navigate(r.serverName, slide);
    }
  }

  onPermissions(userId: string, permissions: Permissions) {
    console.log('permissions of ', userId, ' changegd to', permissions);

    const user = User.get(userId);

    if (user) {
      user.permissions = permissions;
    }
  }

  restrictedSend(msg: string) {
    const user = User.get(this.connection.id);

    if (user?.role === 'teacher') {
      this.ws?.send(msg);
    }
  }

  send(msg: string) {
    this.ws?.send(msg);
  }

  setGlobalPermission(permission: keyof Permissions, value: boolean) {
    this.permissions[permission] = value;
    this.restrictedSend(`[permission]global:${permission}:${value}`);
  }

  setUserPermission(id: string, permission: keyof Permissions, value: boolean) {
    // We don't want the user(s) to remain selected after this action is done.
    const user = User.get(id);
    if (user) {
      user.selected = false;
    }

    this.restrictedSend(`[permission]${id}:${permission}:${value}`);
  }

  setUserEmoji(id: string, emoji: string) {
    this.send(`[emoji]${id}:${emoji}`);
  }

  setUserFeedback(id: string, feedback: string) {
    this.send(`[feedback]${id}:${feedback}`);
  }

  setUserStatus(id: string, userStatus: string) {
    this.send(`[userStatus]${id}:${userStatus}`);
  }

  setTimer(seconds: number) {
    this.restrictedSend(`[timer]${seconds}`);
  }

  raiseHand(id: string, yesOrNo: boolean) {
    this.send(`[hand]${id}:${yesOrNo}`);
  }

  onPermissionsChanged() {
    const user = User.get(this.connection.id) as User;

    if (!user) return;

    let p: keyof Permissions;
    for (p in user.permissions) {
      if (user.permissions[p] === user.oldPermissions[p]) continue;

      this.onSinglePermissionChanged(p, user.permissions[p]);
      user.oldPermissions[p] = user.permissions[p];
    }
  }

  onSinglePermissionChanged(permission: keyof Permissions, value: boolean) {
    if (this.isTeacher()) return;

    switch (permission) {
      case 'audio': {
        const track = this.connection?.currentRoom?.getLocalAudio();

        if (!value) {
          this.isAudioEnabled = false;
          track?.mute();
        }

        break;
      }
      case 'video': {
        const track = this.connection?.currentRoom?.getLocalVideo();

        if (!value) {
          this.isVideoEnabled = false;
          track?.mute();
        }

        break;
      }
      case 'whiteboard': {
        whiteBoardAPI.readonly(this.connection?.currentRoom?.serverName as string, !value);

        break;
      }
      case 'screenshare': {
        const track = this.connection?.currentRoom?.getLocalVideo();
        if (!value) {
          this.isScreenShareEnabled = false;
          track?.mute();
        }
        break;
      }
    }
  }

  setFollow(room: Room, value: boolean) {
    room.follow = value;
    this.restrictedSend(`[follow]${room.name}:${value}`);
  }

  renameRoom(roomName: string, newName: string) {
    this.restrictedSend(`[renameRoom]${roomName}:${newName}`);
  }

  isTeacher() {
    return this.getUser()?.role === 'teacher';
  }

  hasTopMenuControl() {
    return this.getUser()?.permissions.topMenuControl;
  }

  getUser() {
    return User.get(this.connection.id) as User;
  }

  logEvent(text: string) {
    this.eventLog.push([text, Date.now()]);
  }

  updateParticipation = (value: number, userId: string) => {
    const user = User.get(userId);

    // Prevent negative participation count values
    if (user.participationCount === 0 && value === -1) {
      return;
    }

    // update locally to avoid race conditions (which can lead to negative numbers)
    user.participationCount += value;

    this.restrictedSend(`[updateParticipation]${value}:${userId}`);
  };
}

export function getApp() {
  return App.first()!;
}

import { markRaw, nextTick } from 'vue';
import { Model, string } from 'vue-model';
import { config } from '../config';
import { ChatMessage, MAIN_ROOM } from '../types';
import { Connection } from './Connection';
import { User } from './User';
// import JitsiMeetJS from '@solyd/lib-jitsi-meet'
import { getApp } from './App';
import { alert } from '../utils';
import type { MediaType } from '@solyd/lib-jitsi-meet/dist/esm/service/RTC/MediaType';
import type JitsiTrack from '@solyd/lib-jitsi-meet/dist/esm/modules/RTC/JitsiTrack';
import type JitsiLocalTrack from '@solyd/lib-jitsi-meet/dist/esm/modules/RTC/JitsiLocalTrack';
import type JitsiRemoteTrack from '@solyd/lib-jitsi-meet/dist/esm/modules/RTC/JitsiRemoteTrack';
import RTC from '@solyd/lib-jitsi-meet/dist/esm/modules/RTC/RTC';
import { log, WARN } from '../log';

const localTracks = [] as JitsiLocalTrack[];

type x = {
  on(event: string, callback: (track: JitsiTrack) => void): void;
};

const getLocalTrack = async (types: ('audio' | 'camera' | 'desktop')[]) => {
  const app = getApp();

  if (types.includes('camera') && types.includes('desktop')) {
    types.splice(types.indexOf('camera'), 1);
  }

  for (const track of [...localTracks]) {
    const type = track.getType();
    const videoType = track.getVideoType();
    const ttype = type === 'video' ? videoType : type;

    const trackAlreadyThere = types.some((t) => {
      if (t !== ttype) return false;
      if (ttype === 'audio' && track.getDeviceId() !== app.micDeviceId) return false;
      if ((ttype === 'camera' || !ttype) && track.getDeviceId() !== app.cameraDeviceId) return false;
      return true;
    });

    if (trackAlreadyThere) {
      types.splice(types.indexOf(ttype as 'audio' | 'camera' | 'desktop'), 1);
    } else {
      await track.dispose();
      localTracks.splice(localTracks.indexOf(track), 1);
    }
  }

  if (types.length) {
    try {
      const ts = types.map((t) => {
        return t === 'camera' ? 'video' : t;
      });

      const t = (await JitsiMeetJS.createLocalTracks({
        devices: ts,
        micDeviceId: app.micDeviceId ?? undefined,
        cameraDeviceId: app.cameraDeviceId ?? undefined,
      })) as JitsiLocalTrack[];

      localTracks.push(...t);

      app.audioNotPermitted = false;
      app.videoNotPermitted = false;
      app.micDeviceId = localTracks.find((t) => t.getType() === 'audio')?.getDeviceId() ?? app.micDeviceId;
      app.cameraDeviceId = localTracks.find((t) => t.getType() === 'video')?.getDeviceId() ?? app.cameraDeviceId;
    } catch (e) {
      const error = e as Error;

      if (error.name === JitsiMeetJS.errors.track.SCREENSHARING_USER_CANCELED) {
        app.isScreenShareEnabled = false;
      }

      if (error.name === 'NotAllowedError' || error.name === JitsiMeetJS.errors.track.PERMISSION_DENIED) {
        const msg = 'You did not grant permissions for audio or video. They will not be available throughout the session.';

        if (types.includes('audio')) {
          app.isAudioEnabled = false;
          app.audioNotPermitted = true;
        }

        if (types.includes('camera')) {
          app.isVideoEnabled = false;
          app.videoNotPermitted = true;
        }

        const check = [] as MediaType[]; // ('audio'|'video')[]
        if (types.includes('audio')) {
          check.push('video' as MediaType.VIDEO);
        } else if (types.includes('camera')) {
          check.push('audio' as MediaType.AUDIO);
        }

        if (check.length) {
          await Promise.all(
            check.map(async (t) => {
              const granted = await JitsiMeetJS.mediaDevices.isDevicePermissionGranted(t);
              if (t === 'video') {
                app.videoNotPermitted = !granted;
              } else if (t === 'audio') {
                app.audioNotPermitted = !granted;
              }
            }),
          );
          // await JitsiMeetJS.mediaDevices.isDevicePermissionGranted(check).then((granted:boolean) => {
          //   if (check.includes('video')) {
          //     app.videoNotPermitted = !granted
          //   }

          //   if (check.includes('audio')) {
          //     app.audioNotPermitted = !granted
          //   }
          // })
        }

        alert(msg);
      }

      console.log('Couldnt get', types, 'because of:', e, JitsiMeetJS.errors.track.PERMISSION_DENIED);
    }
  }

  const audioTrack = localTracks.find((t) => t.getType() === 'audio');

  // if (audioTrack && !audioTrack._streamEffect?.isEnabled()) {
  //   await audioTrack.setEffect(MicGainEffect)
  // }

  if (types.includes('desktop')) {
    (localTracks.find((t) => t.getType() === 'video' && t.getVideoType() === 'desktop') as unknown as x)?.on(
      JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
      () => {
        app.isScreenShareEnabled = false;
      },
    );
  }

  return localTracks;
};

function convertToJitsiRoomName(base: string, name: string) {
  if (name === MAIN_ROOM) {
    return base.replace(/\s+/g, '_').toLowerCase();
  }

  return (base + '_' + name).replace(/\s+/g, '_').toLowerCase();
}

const confOptions = {
  ...config,
  openBridgeChannel: true,
  startVideoMuted: true,
};

type Stats = {
  avgAudioLevels: Record<string, unknown>;
  bandwidth: { download: number; upload: number };
  bitrate: {
    upload: number;
    download: number;
    audio: { upload: number; download: number };
    video: { upload: number; download: number };
  };
  bridgeCount: number;
  codec: Record<string, unknown>;
  connectionQuality: number;
  framerate: Record<string, unknown>;
  localAvgAudioLevels: number | undefined;
  packetLoss: { total: number; download: number; upload: number };
  resolution: Record<string, unknown>;
};

export class Room extends Model {
  static model = 'Room';
  static primaryKey = 'name';

  name!: string;
  displayName!: string;
  serverName!: string;
  users!: User[];
  conference: any;
  tracks!: JitsiTrack[];
  joined!: boolean;
  joining!: boolean;
  messages!: ChatMessage[];
  dominantSpeaker!: string | null;
  connectionId!: string;
  connection!: Connection;
  customWhiteBoardName!: string;
  whiteBoardEnabled!: boolean;
  whiteBoardUrl!: string;
  follow!: boolean;
  currentSlide!: number;
  teachersCurrentSlide!: number;
  slideNames!: string[];
  display!: 'whiteboard' | 'screenshare';
  slideChangeInProgress!: number;
  enabled!: boolean;
  joinPromise!: Promise<void>;
  resolveJoin!: () => void;
  rejectJoin!: (e: Error) => void;
  boardSetLoading!: boolean;
  boardSetDeleting!: boolean;
  sortedUsers!: User[];

  // whiteboardName!: string
  // currentWhiteboardName!: string

  static fields() {
    return {
      name: this.string(''),
      displayName: this.string(''),
      users: this.hasMany(User),
      tracks: this.array<JitsiTrack>([], Object as any),
      joined: this.boolean(false),
      joining: this.boolean(false),
      messages: this.array<ChatMessage>([], Object as any),
      dominantSpeaker: this.string(null),
      whiteBoardEnabled: this.boolean(true),
      customWhiteBoardName: this.string(null),
      whiteBoardUrl: this.computed((conf) => {
        return config.whiteBoardUrl + (conf.customWhiteBoardName ?? conf.serverName);
      }),
      follow: this.boolean(false),
      currentSlide: this.number(0),
      teachersCurrentSlide: this.number(0),
      timerExpirationDate: this.number(0),
      serverName: this.computed((room) => {
        return convertToJitsiRoomName(room.connection.name, room.name);
      }),
      connectionId: this.string(null),
      connection: this.belongsTo(Connection),

      sortedUsers: this.computed((room) => {
        return room.users
          .filter((u) => u.visible || getApp().showInvisibleUsers)
          .sort((u1, u2) => {
            if (u1.role !== u2.role) {
              return u1.role === 'teacher' ? -1 : 1;
            }

            if (u1.hand !== u2.hand) {
              return u1.hand ? -1 : 1;
            }

            if (u1.hand === true && u2.hand === true) {
              return u1.handTime < u2.handTime ? -1 : 1;
            }

            return u1.name.localeCompare(u2.name);
          });
      }),

      slideNames: this.field<string[]>([], Object as any),
      display: this.string('whiteboard'),
      slideChangeInProgress: this.number(0),
      enabled: this.boolean(true),
      boardSetLoading: this.boolean(false),
      boardSetDeleting: this.boolean(false),

      // whiteboardName: this.string(),
      // currentWhiteboardName: this.string()
    };
  }

  init() {
    this.joinPromise = new Promise((resolve, reject) => {
      this.resolveJoin = resolve;
      this.rejectJoin = reject;
    });

    let conference: any;
    try {
      console.log('[~conference] Initialising conference', this.name);
      conference = markRaw(this.connection.connection.initJitsiConference(this.serverName, confOptions));
    } catch (e: unknown) {
      console.error('Couldnt init conference', e);
      return this.rejectJoin(e as Error);
    }

    const camelCase = (str: string) => {
      return (
        'on' +
        str
          .toLowerCase()
          .split('_')
          .map((s: string, index: number) => s.charAt(0).toUpperCase() + s.slice(1))
          .join('')
      );
    };

    const bindEvents = (eventObj: Record<string, string>, type: string) => {
      Object.entries(eventObj).forEach(([key, value]) => {
        conference.on(value, (...args: any[]) => {
          // This one is particulary annoying
          // if (key !== 'TRACK_AUDIO_LEVEL_CHANGED') {
          //   console.log('[x][' + type + '] ' + key, ...args)
          // }

          if ((this as any)[camelCase(key)]) {
            (this as any)[camelCase(key)](...args);
          }
        });
      });
    };

    bindEvents(JitsiMeetJS.events.conference, 'conference');
    bindEvents(JitsiMeetJS.events.connectionQuality, 'connectionQuality');

    conference.setReceiverConstraints({
      lastN: 5, // Number of videos requested from the bridge.
      defaultConstraints: { maxHeight: 180 }, // Default resolution requested for all endpoints.
    });

    conference.setReceiverVideoConstraint(180);
    conference.setSenderVideoConstraint(180);

    this.conference = conference;
  }

  onLocalStatsUpdated(stats: Stats) {
    const user = this.getLocalUser();
    user && (user.connectionQuality = stats.connectionQuality);
  }

  onRemoteStatsUpdated(id: string, stats: Stats) {
    const user = User.getByJitsiId(id);
    user && (user.connectionQuality = stats.connectionQuality);
  }

  onTalkWhileMuted() {
    getApp().talkWhileMuted = true;
  }

  onNoisyMic() {
    getApp().noisyMic = true;
  }

  onNoAudioInput() {
    getApp().noAudio = true;
  }

  onRecorderStateChanged(session: {
    _connection: any;
    _initiator: any;
    _mode: any;
    _sessionID: string;
    _status: 'on' | 'off' | 'pending';
  }) {
    getApp().recorderState = session._status;
    getApp().recordingSession = session;
  }

  async onLocalTracks(tracks: JitsiTrack[]) {
    const t = this.conference.getLocalTracks() as JitsiTrack[];

    await Promise.all(
      t.map(async (t) => {
        if (tracks.includes(t)) return;

        return this.conference.removeTrack(t);
      }),
    );

    await Promise.all(
      tracks.map(async (track) => {
        if (t.includes(track)) return;

        return this.conference.addTrack(track);
      }),
    );
  }

  getJitsiIdFromTrack = (track: JitsiTrack) => {
    if (track.isLocal()) {
      return this.getLocalUser().jitsiId;
    }
    return (track as JitsiRemoteTrack).getParticipantId();
  };

  onTrackAdded(track: JitsiTrack) {
    if (track.getType() === 'video') {
      track.addEventListener(JitsiMeetJS.events.track.TRACK_VIDEOTYPE_CHANGED, () => {
        console.warn('[~track] Video type changed', track.getVideoType());
        console.warn('We have to re-add the track to the array');
        this.tracks.splice(this.tracks.indexOf(track), 1);
        nextTick(() => {
          this.tracks.push(markRaw(track));

          // Show or hide whiteboard/screenshare based on available tracks
          this.setDisplay();

          // Show or hide video box
          this.showVideoBox();
        });
      });
    }

    this.tracks.push(markRaw(track));

    // TODO: How often does that happen?
    // Dont show any videobox if we cant identify the user of the track
    if (!User.getByJitsiId((track as JitsiRemoteTrack).getParticipantId())) {
      console.warn('[~track] Couldnt identify user of track', track);
      console.warn('Not showing videobox');
      return;
    }

    // Once a video (camera) track has been added, it is not removed by Jitsi until the user leaves the conference.  Instead, it is muted/unmuted.  This section is listening for mutes/unmutes.
    // Show/hide the the video box based on changes of "muting/unmuting".
    track.addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, () => {
      // Show or hide video box
      this.showVideoBox();
    });

    // Show or hide whiteboard/screenshare based on available tracks
    this.setDisplay();

    // Show or hide video box
    this.showVideoBox();
  }

  onTrackRemoved(track: JitsiTrack) {
    const type = track.getType();
    const videoType = track.getVideoType();
    const jitsiId = this.getJitsiIdFromTrack(track);
    const trackId = track.getTrackId();

    const index = this.tracks.findIndex((t) => trackId === t.getTrackId());

    const user = User.getByJitsiId(jitsiId);

    if (index === -1) {
      const tracks = this.tracks
        .map((t) => [t.getType(), t.getVideoType(), t.getTrackId(), this.getJitsiIdFromTrack(t)].join('|'))
        .join(',\n');

      const hasMatchingTrack = this.tracks.some(
        (t) => type === t.getType() && videoType === t.getVideoType() && jitsiId === this.getJitsiIdFromTrack(t),
      );
      
      const systemErrorReport = 
        `System Error Report: A/an ${type} track of type ${videoType} could not be removed.
        TrackId: ${trackId}
        JitsiId: ${jitsiId}.
        user.id: ${user?.id}.
        user.name: ${user?.name}.
        JitsiId: ${jitsiId}.
        Potential Match: ${hasMatchingTrack}.
        Available tracks were:
        ${tracks}`;
      
      log(WARN, systemErrorReport);

      getApp().ws?.send(
        '[recorderErrorReport]' +
          JSON.stringify({
            index,
            type,
            videoType,
            jitsiId,
          }),
      );

      return;
    }

    this.tracks.splice(index, 1);

    // Show or hide whiteboard/screenshare based on available tracks
    this.setDisplay();

    // If it's a video track being turned off, we need to set the user's "isOnCamera" setting to false.
    if (videoType === 'camera') {
      user.isOnCamera = false;
    }

    // Show or hide video box
    this.showVideoBox();
  }

  // Show the video box if there someone is on video
  showVideoBox() {
    let count = 0;
    User.all()
      .filter((u) => u.visible)
      .forEach((u) => {
        if (u.isOnCamera) {
          count++;
        }
      });

    count > 0 ? (getApp().showVideo = true) : (getApp().showVideo = false);

    // issues with being on video and screenshare concurrently...
    // 1.) bring on video to start, then turning on screenshare: The video will turn off and show screenshare.  AFter turning OFF screenshare, the video box opens for both and the video is displayed.
    // 2.) being on screenshare to start, then turning on video: THe video will not display for local or non local.  After turning OFF screenshare, the video box opens for both and the video is displayed.
  }

  setDisplay() {
    const videoTracks = this.tracks.filter((t) => t.getType() === 'video');
    this.display = videoTracks.some((t) => t.getVideoType() === 'desktop') ? 'screenshare' : 'whiteboard';
    // getApp().showVideo = videoTracks.some((t) => t.getVideoType() !== 'desktop')
  }

  onTrackAudioLevelChanged(id: string, volume: number) {
    const user = User.getByJitsiId(id);

    if (!user) return;

    user.audioLevel = volume;
  }

  onConferenceJoined() {
    // see fixme in leave()
    // when someone is on mic and changes rooms, it somehow calls this twice
    if (this.joined && !this.joining) return;

    this.joined = true;
    this.joining = false;

    // Make sure that the user off mic., off video, and off screensharing upon joining a room.  This is useful for when changing between breakout rooms and the main room.
    const app = getApp();
    app.isScreenShareEnabled = false;
    app.isVideoEnabled = false;
    app.isAudioEnabled = false;

    return this.refreshLocalTracks().finally(() => {
      const index = this.messages.length;
      app.ws?.send(`[updateMe]${index}`);
      if (app.currentChat !== app.currentPrivateChat) {
        app.currentChat = this.name;
      }
      console.log('[~conference] joined', this.name);
      this.resolveJoin();
    });
  }

  async refreshLocalTracks() {
    const { isScreenShareEnabled, isAudioEnabled, isVideoEnabled } = getApp();

    const tracks = [] as ('audio' | 'camera' | 'desktop')[];
    if (isAudioEnabled) {
      tracks.push('audio');
    }
    if (isVideoEnabled) {
      tracks.push('camera');
    }
    if (isScreenShareEnabled) {
      tracks.push('desktop');
    }

    return getLocalTrack(tracks).then((tracks) => this.onLocalTracks(tracks));
  }

  onConferenceFailed() {
    console.log('[~conference] joining conference failed');
    this.connection.overlayMessage = 'An error occured while joining the conference. Please refresh!';
    this.joined = false;
    this.joining = false;
    this.rejectJoin(new Error('Couldnt join conference'));
  }

  onConferenceLeft() {
    console.log('[~conference] local user left');
    this.joined = false;
    this.joining = false;
    getApp().showVideo = false; // To prevent the video box remaining open when moving among breakout rooms.
  }

  onUserJoined(id: string, user: any) {
    console.log('[~conference] user joined', id, user);
  }

  onUserLeft(id: string, user: any) {
    console.log('[~conference] user left', id, user);
    // User.get(id)?.delete()
  }

  onDominatSpeakerChanged(id: string) {
    console.log('[~conference] dominant speaker changed', id);
    this.dominantSpeaker = id;
  }

  sendMessage(msg: string, to = '') {
    getApp().ws?.send(
      '[chat]' +
        JSON.stringify({
          to,
          msg,
        }),
    );
  }

  async leave() {
    console.log('[~conference] leaving', this.name);

    const tracks = this.tracks.filter((t) => t.isLocal());

    // FIXME: this leads to onConferenceJoined being called for no reason. Maybe we should just renmove it?
    await Promise.all(tracks.map((track) => this.conference.removeTrack(track)));
    return this.conference.leave().then(() => {
      // this.unbindEvents()
    });
  }

  async join() {
    console.log('[~conference] joining', this.name);
    this.joining = true;
    // if (!this.conference) {
    this.init();
    // }
    return this.conference.join();
  }

  getNonLocalVideo() {
    return this.tracks.find((track) => !track.isLocal() && track.getType() === 'video');
  }

  getLocalVideo() {
    return this.tracks.find((track) => track.isLocal() && track.getType() === 'video') as JitsiLocalTrack | undefined;
  }

  getLocalAudio() {
    return this.tracks.find((track) => track.isLocal() && track.getType() === 'audio') as JitsiLocalTrack | undefined;
  }

  getLocalTracks() {
    return this.tracks.filter((track) => track.isLocal()) as JitsiLocalTrack[];
  }

  getDominantVideo() {
    return this.tracks.find((track) => {
      return (
        !track.isLocal() &&
        track.getType() === 'video' &&
        this.conference &&
        (track as JitsiRemoteTrack).getParticipantId() === this.conference.dominantSpeaker
      );
    }) as JitsiRemoteTrack | undefined;
  }

  getScreenShareVideo() {
    return this.tracks.find((track) => {
      return !track.isLocal() && track.getType() === 'video' && track.getVideoType() === 'desktop';
    });
  }

  getLocalUserId() {
    return this.connection.id;
  }

  getLocalUser() {
    return User.get(this.connection.id) as User;
  }

  getUserEmoji(id: string) {
    return User.get(id)?.emoji;
  }

  getUserFeedback(id: string) {
    return User.get(id)?.feedback;
  }

  getUserStatus(id: string) {
    return User.get(id)?.userStatus;
  }

  getUserHand(id: string) {
    return User.get(id)?.hand ? '✋' : '';
  }

  getUserName(id: string) {
    return User.get(id)?.name ?? 'Unknown User';
  }
}

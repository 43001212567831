<template>
  <div
    class="rooms"
    :style="{'min-height': app.isRecorder ? '625px' : currentHeight + 'px', 'max-height': app.dockRight ? undefined : currentHeight + 'px'}"
  >
    <div class="rooms-header">
      <span>ROOMS</span>
      <i
        v-if="app.isTeacher()"
        class="bx bx-checkbox-square"
        :class="{active: app.permissions.rooms}"
        :title="app.permissions.rooms ? 'Disable Room Switching' : 'Enable Room Switching'"
        @click="disableSwitching"
      />
      <i
        v-if="app.isTeacher()"
        class="bx bx-x"
        title="Close Breakout Rooms"
        @click="closeRooms"
      />
    </div>
    <div class="rooms-list">
      <Room
        v-for="room in rooms"
        :key="room.name"
        :name="room.name"
      />
    </div>
    <div
      v-show="!app.dockRight"
      class="wall"
      @pointerdown="moveWall"
    />
  </div>
</template>

<style lang="scss" scoped>
@import '../main.scss';

div.rooms {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  position: relative;

  > div.rooms-header {
    display: flex;
    font-size: 14px;
    height: 26px;
    color: white;
    background: $gray;
    align-items: center;
    padding: 0 6px;

    > span {
      flex-grow: 1;
    }

    > i {
      cursor: pointer;
      font-size: 20px;

      &.active {
        color: $icon-highlight;
      }
    }
  }

  > div.rooms-list {
    display: flex;
    flex-direction: column;
    user-select: none;
    overflow: auto;
  }

  > div.wall {
    position: absolute;
    bottom: -3px;
    width: 100%;
    height: 6px;
    cursor: n-resize;

    &:hover {
      background: rgba(0,0,0,0.5);
    }
  }
}

</style>

<script lang="ts">
import { computed, ref, defineComponent } from 'vue'
import { Room as RoomModel } from '../models/Room'
import Room from './Room.vue'
import { getApp } from '../models/App'
import { User } from '../models/User'
import { confirm } from '../utils'
import { DEFAULT_ROOMS_HEIGHT } from '../config'
export default defineComponent({
  name: 'Rooms',
  components: {
    Room
  },
  setup () {
    const app = getApp()
    const rooms = computed(() => RoomModel.all())

    const closeRooms = async () => {
      // Make sure it wasn't an accidental click to close breakout rooms.
      const yes = await confirm('Do you really want to close all breakout rooms?', undefined, true)

      if (yes) {
        app.restrictedSend('[breakout]close')
        // Update the event log about the fact that the breakout rooms were closed.
        app.send(`[eventLog]All rooms were closed by ${app.getUser()?.name}.`)
      }
    }

    const disableSwitching = () => {
      app.setGlobalPermission('rooms', !app.permissions.rooms)
    }

    const currentHeight = ref(Number(localStorage.getItem('roomsHeight')) || DEFAULT_ROOMS_HEIGHT)

    const moveWall = (ev: PointerEvent) => {
      (ev.target as HTMLDivElement).setPointerCapture(ev.pointerId)

      const onUp = () => {
        (ev.target as HTMLDivElement).releasePointerCapture(ev.pointerId)
        document.removeEventListener('pointermove', onMove)
        document.removeEventListener('pointerup', onUp)
      }

      const onMove = (ev: PointerEvent) => {
        currentHeight.value += ev.movementY
        localStorage.setItem('roomsHeight', currentHeight.value.toString())
      }

      document.addEventListener('pointermove', onMove)
      document.addEventListener('pointerup', onUp)
    }
    return { rooms, closeRooms, disableSwitching, app, currentHeight, moveWall }
  }
})

</script>

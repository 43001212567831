<template>
  <div class="input-output">
    <div>
      <i
        class="bx bxs-microphone"
        :class="{active: showAudioInputs}"
        @click="showAudioInputs = !showAudioInputs; showAudioOutputs = false"
      />

      <ul
        v-if="showAudioInputs"
        class="contextmenu"
      >
        <li
          style="pointer-events: none"
          class="list-header"
        >
          {{ audioDevices.length ? 'Input' : 'No Device Found' }}
        </li>
        <li
          v-for="device in audioDevices"
          :key="device.deviceId"
          :class="{active: device.deviceId === app.micDeviceId }"
          @click="selectAudio(device.deviceId)"
        >
          {{ device.label }}
        </li>
      </ul>
    </div>
    <input
      type="range"
      min="0"
      max="2"
      step="0.01"
      :value="input"
      @input="$emit('update:input', $event.target.value)"
    >
    <div>
      <i
        class="bx bxs-volume"
        :class="{active: showAudioOutputs}"
        @click="showAudioOutputs = !showAudioOutputs; showAudioInputs = false"
      />
      <ul
        v-if="showAudioOutputs"
        class="contextmenu"
      >
        <li
          style="pointer-events: none"
          class="list-header"
        >
          {{ outputDevices.length ? 'Output' : 'No Device Found' }}
        </li>
        <li
          v-for="device in outputDevices"
          :key="device.deviceId"
          :class="{active: device.deviceId === app.outputDeviceId }"
          @click="selectOutput(device.deviceId)"
        >
          {{ device.label }}
        </li>
      </ul>
    </div>
    <input
      type="range"
      min="0"
      max="1"
      step="0.01"
      :value="output"
      @input="$emit('update:output', $event.target.value)"
    >
  </div>
</template>

<style scoped lang="scss">
@import '../main.scss';
@import '../slider.scss';

div.input-output {
  display: flex;
  align-items: center;
  padding: 0 4px;
  margin-top: 2px;
}

div.input-output > div {
  margin-right: 2px;
  display: flex;
  align-items: center;
  position: relative;

  &:not(:first-child) {
    margin-left: 4px;
  }

  > i {
    color: $gray;
    font-size: 16px;
    cursor: pointer;

    &:hover, &.active {
      color: $icon-highlight;
    }
  }
}

div.input-output > input {
  flex-grow: 1;
}

.contextmenu {
  position: absolute;
  top: 20px;
  left: 0;
  background: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px 0;
  margin: 0;
  list-style: none;
  color: black;
  z-index: 100;
  font-size: 16px;
  line-height: 1;
  white-space: nowrap;
  z-index: 10; /* Ensuring menus open on top of the whiteboard / displayarea <div> */

  > li {
    padding: 5px 20px;
    cursor: pointer;

    &:hover {
      background: #ddd;
    }

    &.active {
      background: #ccc;
    }

    &.list-header {
      font-weight: bold;
      border-bottom: 1px solid black;
      margin: 5px 0;
    }
  }
}

</style>

<script lang="ts">
import { ref, computed, defineComponent } from 'vue'
import { getApp } from '../models/App'
// import JitsiMeetJS from '@lyno/lib-jitsi-meet'
export default defineComponent({
  name: 'InputOutputSlider.vue',
  props: [ 'input', 'output' ],
  emits: [ 'update:input', 'update:output' ],
  setup () {
    const app = getApp()

    const showAudioInputs = ref(false)
    const showAudioOutputs = ref(false)

    const audioDevices = computed(() => app.devices.filter((device) => device.kind === 'audioinput' && device.deviceId))
    // const videoDevices = computed(() => app.devices.filter((device) => device.kind === 'videoinput'))
    const outputDevices = computed(() => app.devices.filter((device) => device.kind === 'audiooutput' && device.deviceId))

    const selectAudio = (id: string) => {
      app.micDeviceId = id
      app.connection?.currentRoom?.refreshLocalTracks()
      showAudioInputs.value = false
    }

    // const selectVideo = (id: string) => {
    //   app.cameraDeviceId = id
    //   app.connection?.currentRoom?.refreshLocalTracks()
    // }

    const selectOutput = (id: string) => {
      app.outputDeviceId = id
      JitsiMeetJS.mediaDevices.setAudioOutputDevice(id)
      showAudioOutputs.value = false
    }

    return {
      app,
      showAudioInputs,
      showAudioOutputs,
      audioDevices,
      outputDevices,
      selectAudio,
      selectOutput
    }
  }
})
</script>
